import React from 'react'
import Header from './Header'
import Footer from './Footer'

export default function Layout({ children , data }) {
    return (
        <>
            <Header key={'header'} data={data} />
            <div className="container-lg">{children}</div>
            <Footer key={'header'} data={data} />
        </>
    )
}
