import React from 'react'
import './../commonCss/compitionCard.css'
import { Link } from 'react-router-dom'
export default function CompitionCard({ data }) {
    const winners = [
        { name: 'SHAMIS AL HAWAJER', rank: 1, number: 168 },
        { name: 'AlI AL HAWAJER', rank: 2, number: 200 },
        { name: 'AlI AL HAWAJER', rank: 3, number: 202 }
    ]
    return (
        <div className='col-lg-4 col-md-6 col-sm-6 col-12 my-1'>
            <div className='bgGray text-start mx-3 pb-3 row'>
                <div className='compitionCardHeader roboto-medium text-center' >{data?.championshipName}</div>
                {!!data?.goldHorse?.horseName &&
                    <div className='d-flex align-items-center ps-5 py-2'>
                        <div className='cupImageContainer' >
                            <img src='./svgs/cupGold.svg' alt='svg' />
                            <div className='cuprank text-white'>1</div>
                        </div>
                        <div className='ms-3'>{data?.goldHorse?.horseNumber} - {data?.goldHorse?.horseName}</div>
                    </div>
                }
                {!!data?.silverHorse?.horseName &&
                    <div className='d-flex align-items-center ps-5 py-2'>
                        <div className='cupImageContainer' >
                            <img src='./svgs/cupSilver.svg' alt='svg' />
                            <div className='cuprank text-white'>2</div>
                        </div>
                        <div className='ms-3'>{data?.silverHorse?.horseNumber} - {data?.silverHorse?.horseName}</div>
                    </div>
                }
                {!!data?.bronzeHorse?.horseName &&
                    <div className='d-flex align-items-center ps-5 py-2'>
                        <div className='cupImageContainer' >
                            <img src='./svgs/cup.svg' alt='svg' />
                            <div className='cuprank text-white'>3</div>
                        </div>
                        <div className='ms-3'>{data?.bronzeHorse?.horseNumber} - {data?.bronzeHorse?.horseName}</div>
                    </div>
                }
                {/* <Link className='px-5 roboto-regular greenColor mt-3 text-end' >Judge Card <i className="ms-1 fa fa-arrow-right"></i> </Link>   */}
            </div>
        </div>
    )
}


