import React from 'react'
import AddNewHorseForm from '../components/AddNewHorseForm'

export default function AddNewHorsePage({ toast }) {
    return (
        <>
            <AddNewHorseForm key={'addNewHorseForm'} toast={toast} />
        </>
    )
}
