import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';



export default function TranslationText({ defaultText, textkey }) {
    const { translations, loading, error } = useSelector((state) => state.translations);
    const [text, setText] = useState(defaultText)

    useEffect(() => {
        setText((translations[textkey]?.trim() ?? "") || defaultText);
    }, [translations]);


    return (<>{translations[textkey] || defaultText}</>)
}


export function useTranslate() {
    const { translations, loading, error } = useSelector((state) => state.translations);

    const translate = (keyLabel, defaultLabel) => {
        debugger;
        return translations[keyLabel] || defaultLabel;
    }
    return { translate }
}