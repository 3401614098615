import React, { useEffect, useRef, useState } from "react";
import NetaqInput from "./NetaqInput";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import axios from "./../axios/axios";
import { useTranslate } from "./TranslationText";
import "react-toastify/dist/ReactToastify.css";
import CustomButton from "./CustomButton";
import Loader from './Loader'
import axiosBackend from "./../axios/axios";

export default function LoginForm({ toast, setShowOTP, setCredForOTP, otpSent }) {
  const navigate = useNavigate();
  const currentlocation = useLocation();
  const { translate } = useTranslate();
  const [isSubmitting, setIsSubmitting] = useState(false);


  const [loginCred, setLoginCred] = useState({
    email: "",
    password: "",
    firebaseDeviceId: "",
  });

  const searchParams = new URLSearchParams(currentlocation.search);
  const redirectURL = searchParams.has("redirectUrl")
    ? searchParams.get("redirectUrl")
    : null;



  const handleChange = (event) => {
    setLoginCred({
      ...loginCred,
      [event.target.name]: event.target.value,
    });
    setCredForOTP({
      ...loginCred,
      [event.target.name]: event.target.value,
    })
  };

  const handleSubmit = async (event) => {
    event?.preventDefault();
    setIsSubmitting(true);

    try {
      const login = await axiosBackend.post('/api/Account/login', { email: loginCred.email, password: loginCred.password })

      if (login.status == 200) {
        if (login?.data?.responseCode == "200") {
          const token = login?.data?.result?.token ?? "";
          const userProfile = JSON.stringify(login?.data?.result);
          localStorage.setItem('accessTokenforECUP', token);
          localStorage.setItem('ecupUserProfile', userProfile);
          toast.success(translate('web.loggedInSuccessfully', 'Logged In Successfully'));
          if (redirectURL != null) {
            navigate(redirectURL);
          } else {
            navigate("/profile");
          }
          setIsSubmitting(false);
        } else if (login?.data?.responseCode == "500") {
          setIsSubmitting(false);
          setShowOTP(true);
        }
        else {
          toast.error(translate(login?.data?.responseText, login?.data?.responseText));
          setIsSubmitting(false);
        }

      } else {
        toast.error(translate('web.invalidUserNameOrPassword', 'Invalid User Name Or Password'));
        setIsSubmitting(false);

      }
    } catch (error) {
      toast.error(translate('web.cannotLoginPleaseContactTheAdmin', 'Cannot Login Please Contact The Admin'));
      console.error('error in Login', error);
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (otpSent) {
      handleSubmit();
    }
  }, [otpSent])



  return (
    <>

      <form
        className="row mx-0 bg-gray-p-25bg-gray-p-25"
        onSubmit={handleSubmit}
      >
        <h2>{translate("web.login", "Login")}</h2>
        <span>
          {translate("web.donthaveanaccount", "Don’t have an account?")}{" "}
          <Link to={"/register"} className="mainColor">
            {translate("web.registerNewAccount", "Register New Account")}
          </Link>{" "}
        </span>
        <NetaqInput
          name={"email"}
          key={"email"}
          placeholder={translate("web.email", "Email")}
          label={translate("web.email", "Email")}
          type={"email"}
          required={true}
          onChange={handleChange}
        />
        <NetaqInput
          name={"password"}
          key={"password"}
          placeholder={translate("web.password", "password")}
          label={translate("web.password", "password")}
          type={"password"}
          required={true}
          onChange={handleChange}
        />

        <Link to={"/forgetpassword"} className="mainColor my-2">
          {translate("web.forgetPassword", "Forget Password ?")}
        </Link>

        {/* <ReCAPTCHA
          sitekey={recaptchaSiteKey}
          size="invisible"
          ref={recaptchaRef}
          onChange={(token) => {
            setRecaptchaToken(token);
            if (token !== "") {
              setRecaptchaVaidate(true);
            }
          }}
        /> */}
        {isSubmitting ?
          <Loader /> :
          <CustomButton key={'submit'} type="submit" className="bgMain" htmlText={translate('web.login', 'Login')} />
        }


      </form>
    </>
  );
}
