import React from 'react'
const BaseURL = process.env.REACT_APP_BACKEND_API;

export default function AboutSection({ data }) {

    return (
        <div id="about" className="secondSectionContainer">
            <div className="aboutSectionContainer">
                <div className="aboutImageContainer">
                    {data?.aboutImage?.length > 0 &&
                        <img src={BaseURL + data?.aboutImage[0].url} alt="ChangeMe" />
                    }
                </div>
                <div className="aboutTextContainer">
                    <span className="lablelTitle roboto-light">
                        {data?.aboutTitle}
                    </span>
                    <div className="postBody" dangerouslySetInnerHTML={{
                        __html: data?.aboutDescription?.markup
                    }} />
                </div>
            </div>
            <div className="aboutSectionContainer objectiveSection">
                <div className="aboutImageContainer" style={{ textAlign: 'center' }}>
                    {data?.objectiveImage?.length > 0 &&
                        <img src={BaseURL + data?.objectiveImage[0].url} style={{ height: "360px", width: 'auto' }} alt="ChangeMe" />
                    }

                </div>
                <div className="aboutTextContainer align-items-end">
                    <span className="lablelTitle roboto-light">
                        {data?.objectiveTitle}
                    </span>
                    <div className="postBody" style={{ height: '20%', display: 'table' }} dangerouslySetInnerHTML={{ __html: data?.objectiveDescription?.markup }} />

                </div>
            </div>

            <div style={{ marginTop: 50 }} className="aboutSectionContainer">
                <div className="aboutImageContainer">
                    {data?.trophiesImage?.length > 0 &&
                        <img src={BaseURL + data?.trophiesImage[0].url} alt="ChangeMe" />
                    }
                </div>
                <div className="aboutTextContainer" style={{ marginTop: 60 }}>
                    <span className="lablelTitle roboto-light">
                        {data?.trophiesTitle}
                    </span>
                    <div className="postBody" dangerouslySetInnerHTML={{
                        __html: data?.trophiesDescription?.markup
                    }} />

                </div>
            </div>
        </div>
    )
}
