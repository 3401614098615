import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslate } from './TranslationText'

export default function Footer({ data }) {
    const { translate } = useTranslate();
    return (
        <>
            <div className="footerContainer">
                <div className="container">
                    <br />
                    <br />
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <img className="headerLogo" src="./img/footer.png" alt="logo" />
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 marginTop15">
                            <div className="col-sm-12 footerTitleItem roboto-bold">
                                {translate('web.menu', 'Menu')}
                            </div>
                            <a href="" className="col-sm-12 footerItem roboto-medium">
                                {translate('Home', 'Home')}
                            </a>
                            <a href="" className="col-sm-12 footerItem roboto-medium">
                                {translate('Rules', 'Rules')}
                            </a>
                            <a href="" className="col-sm-12 footerItem roboto-medium">
                                {translate('Calendar', 'Calendar')}
                            </a>
                            <a href="" className="col-sm-12 footerItem roboto-medium">
                                {translate('News', 'News')}
                            </a>
                            <a href="" className="col-sm-12 footerItem roboto-medium">
                                {translate('About', 'About')}
                            </a>
                            <a href="" className="col-sm-12 footerItem roboto-medium">
                                {translate('Contact', 'Contact Us')}

                            </a>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="col-sm-12 footerTitleItem roboto-bold">
                                {translate('Contact', 'Contact Us')}
                            </div>
                            <a href="" className="col-sm-12 footerItem roboto-medium">
                                <i className="fa fa-map-marker" aria-hidden="true"></i> {data?.address}
                            </a>
                            <a href="" className="col-sm-12 footerItem roboto-medium">
                                <i className="fa fa-phone" aria-hidden="true"></i>
                                {data?.phone}
                            </a>
                            <a href="" className="col-sm-12 footerItem roboto-medium">
                                <i className="fa fa-envelope" aria-hidden="true"></i>
                                {data?.email}
                            </a>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-12" style={{ opacity: 0 }}>.</div>
                                <div className="col-lg-6 col-md-6 col-sm-12 footerTitleItem roboto-bold">
                                    {translate('SocialMedia', 'Social Media')}
                                    <div className="row">
                                        <Link target='_blank' to={data?.facebook} className="col-sm-3 SocialMediaIcon">
                                            <i className="fa fa-facebook" aria-hidden="true"></i>
                                        </Link>
                                        <Link target='_blank' to={data?.twitterX} className="col-sm-3 SocialMediaIcon">
                                            <i className="fa fa-twitter" aria-hidden="true"></i>
                                        </Link>
                                        <Link target='_blank' to={data?.instagram} className="col-sm-3 SocialMediaIcon">
                                            <i className="fa fa-instagram" aria-hidden="true"></i>
                                        </Link>
                                        {/* <Link to={data?.} className="col-sm-3 SocialMediaIcon">
                                            <i className="fa fa-pinterest" aria-hidden="true"></i>
                                        </Link> */}
                                    </div>
                                </div>
                                <br />
                                <br />
                                <br />
                                {/* <div className="col-sm-12 footerTitleItem roboto-bold">
                                    NewsLetter
                                </div>
                                <div className="col-sm-12">
                                    <div className="newsLetterContainer">
                                        <input className="newsLetterInput" placeholder="Enter Your Email" type="text" name="d" id="s" />
                                        <i className="fa fa-envelope iconRed" style={{cursor:'pointer'}} aria-hidden="true"></i>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="row bordertop">
                        <div className="col-sm-12 text-center roboto-regular">
                            © {translate('web.copyRights', '2021 All Rights Reserved')}
                        </div>
                    </div>
                    <br />
                </div>
            </div>
        </>
    )
}
