import React from 'react'

export default function CustomButton({ type = '', onClick, className = '', htmlText = '', containerClass = '' , disabled=false }) {
    return (
        <>
            {type == 'submit' &&
                <div className={`row mx-0 ${containerClass}`}>
                    <button type='submit' disabled={disabled} onClick={onClick} className={`btn text-white  ${className}`} dangerouslySetInnerHTML={{ __html: htmlText }} />
                </div>
            }
            {type == 'navigate' &&
                <div className={`row mx-0 ${containerClass}`}>
                    <div onClick={onClick} disabled={disabled} className={`btn  ${className}`} dangerouslySetInnerHTML={{ __html: htmlText }} />
                </div>
            }

        </>
    )
}
