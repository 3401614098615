import axios from "axios";

const baseURL = process.env.REACT_APP_BACKEND_API;
// const baseURL = process.env.REACT_APP_Local_BACKEND_API;

const axiosBackend = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json", // Set default content type (optional)
    "accept-language": getCurrentLanguageUmbraco(),
    "Referer": window.location.href,
    "Accept": 'application/json, text/plain, */*',
  },
});

// Function to inject access token and language into headers before each request
axiosBackend.interceptors.request.use(
  (config) => {
    const accessToken = getAccessToken();
    const language = getCurrentLanguage();
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    if (language) {
      config.params = {
        ...config.params,
        language: language,
      };
    }
    return config;
  },
  (error) => Promise.reject(error)
);

function getAccessToken() {
  const token = localStorage.getItem("accessTokenforECUP");
  return token;
}

function getCurrentLanguage() {
  // Replace this with your logic to retrieve the current language
  // For example, you can retrieve it from localStorage or from a state variable
  const lang = localStorage.getItem("ecupLang") ?? "en";
  if (lang == "en") return 0;
  else return 1; // Defaulting to English for demonstration
}
function getCurrentLanguageUmbraco() {
  // Replace this with your logic to retrieve the current language
  // For example, you can retrieve it from localStorage or from a state variable
  const lang = localStorage.getItem("ecupLang") ?? "en";
  if (lang == "en") return "en-US";
  else return "ar-AE"; // Defaulting to English for demonstration
}

export default axiosBackend;
