import React, { useEffect, useState } from 'react';
import PrizeCard from './PrizeCard';
import axiosBackend from '../axios/axios';
import { useTranslate } from './TranslationText';

export default function PrizeMoney({ showId }) {
    const [showPrizeListForChampions, setShowPrizeListForChampions] = useState([]);
    const [showPrizeListForClasses, setShowPrizeListForClasses] = useState([]);
    const { translate } = useTranslate();
    async function getShowPrizeList() {
        try {
            const response = await axiosBackend.get(`/api/ShowEntry/GetShowPrizeByShowId?id=${showId}`);
            if (response.data.responseCode == "200") {
                setShowPrizeListForChampions(response.data.result.showPrizeListForChampions)
                setShowPrizeListForClasses(response.data.result.showPrizeListForClasses)
            } else {
                console.error('error in getting Show Prize List')
            }

        } catch (error) {
            console.error('error in getting Show Prize List API')
        }

    }
    useEffect(() => {
        getShowPrizeList();
    }, [])

    return (
            (!!showPrizeListForChampions.length > 0 || !!showPrizeListForClasses.length > 0) && <>
                <h2 className='roboto-custom-black' >
                    {translate("web.prizeMoney", "Prize Money")}
                </h2>
                {!!showPrizeListForClasses.length > 0 &&
                    <>
                        <h5 className='roboto-custom-black mt-3'>
                            {translate("web.classesPrize", "Classes Prize")}
                        </h5>
                        {!!showPrizeListForClasses && showPrizeListForClasses.map((prize , index) => (<PrizeCard key={'prize'+index} data={prize} />))}

                    </>
                }
                {!!showPrizeListForChampions.length > 0 &&
                    <>
                        <h5 className='roboto-custom-black mt-3'>
                            {translate("web.championsPrize", "Champions Prize")}
                        </h5>
                        {!!showPrizeListForChampions && showPrizeListForChampions.map((prize , index) => (<PrizeCard key={'s' + index} data={prize} specialRanks={true} />))}
                    </>}

            </>
    )
}
