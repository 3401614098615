import React, { useEffect, useRef, useState } from 'react'
import NetaqInput from './NetaqInput'
import { useTranslate } from './TranslationText'
import CustomButton from './CustomButton';
import axiosBackend from '../axios/axios';
import Loader from './Loader';


export default function AccountSettingTab({ toast }) {
    const { translate } = useTranslate();
    // const userProfile = JSON.parse(localStorage.getItem('ecupUserProfile')) ?? {};
    const [userProfile, setUserProfile] = useState({});
    const [profileLoaded, setProfileLoaded] = useState(false);
    const [isUpdatingProfile, setIsUpdatingProfile] = useState(false);
    const [isChangingPassword, setIsChangingPassword] = useState(false);
    const [accountData, setAccountData] = useState({});
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);
    const [countries, setCountries] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    const [currency, setCurreny] = useState([]);
    const [nationality, setNationality] = useState([]);
    const [country, setCountry] = useState([]);
    const currentLanguage = localStorage.getItem('ecupLang') ?? 'en';
    const [bankCountry, setBankCountry] = useState([]);

    // get Lists Data
    async function getCountries() {
        const response = await axiosBackend.get('/api/Lookup/GetCountries');
        const formattedData = response?.data?.result.map((x) => ({
            label: currentLanguage == 'en' ? x.name : x.arName,
            text: currentLanguage == 'en' ? x.name : x.arName,
            value: x.id,
            ...x
        }))
        setCountries(formattedData);
    }
    async function GetCurrencies() {
        const response = await axiosBackend.get('/api/Lookup/GetCurrencies');
        const formattedData = response?.data?.result.map((x) => ({
            text: x.text,
            value: x.value,
            ...x
        }))
        setCurrencies(formattedData);
    }
    const setPhoneNumber = (value) => {
        setAccountData((prev) => ({
            ...prev,
            'phoneNo': value
        }));
    }


    async function getProfile() {
        try {
            const res = await axiosBackend.get('/api/account/profile');
            if (res.status == 200) {
                setUserProfile(res.data);
                const selecetedBankCountry = countries.find((item) => item.value === res?.data?.bankCountryId);
                setBankCountry(selecetedBankCountry || null);
                const selecetedNationality = countries.find((item) => item.value === res?.data?.nationalityId);
                setNationality(selecetedNationality || null);
                const selecetedcountry = countries.find((item) => item.value === res?.data?.countryId);
                setCountry(selecetedcountry || null);
                setProfileLoaded(true);
            }
        } catch (error) {
            toast.error(translate('web.anErrorAccuredDuringGettingProfile', 'An Error Accured During Getting Profile'))
        }
    }

    const handleChange = (e) => {
        setAccountData((prev) => ({
            ...prev,
            [e.target.name]: e.target.value
        }));
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsUpdatingProfile(true);
        try {
            const res = await axiosBackend.post('api/account/UpdateAccount', { ...accountData, id:userProfile?.id ,bankCountryId: bankCountry?.value, nationalityId: nationality?.value, countryId: country?.value });
            if (res.status == 200) {
                toast.success(translate('web.savedSuccessfully', 'Saved Successfully'));
                setIsUpdatingProfile(false);
            } else {
                toast.error(translate('web.errorInUpdatingProfile', 'Error In Updating Profile'));
                setIsUpdatingProfile(false);
            }
        } catch (error) {
            toast.error(translate('web.errorInUpdatingProfile', 'Error In Updating Profile'));
            setIsUpdatingProfile(false);
        }

    }


    const changePassword = async (e) => {
        e.preventDefault();
        if (currentPassword == "") {
            toast.error(translate('web.currentPasswordIsRequired', 'Current Password Is Required'));
            return;
        }
        if (newPassword == "") {
            toast.error(translate('web.newPasswordIsRequired', 'New Password Is Required'));
            return;
        }
        if (newPassword != confirmPassword) {
            toast.error(translate('web.passwordsIsNotMatched', 'Passwords Is Not Matched'));
            return;
        }
        try {
            setIsChangingPassword(true);
            const response = await axiosBackend.post('/api/Account/ChangePassword', { currentPassword: currentPassword, newPassword: newPassword, userId: userProfile.id })
            if (response.status == 200) {
                setIsChangingPassword(false);
                toast.success(translate('web.passwordChangedSuccessfully', 'Password Changed Successfully'))
            } else {
                setIsChangingPassword(false);
                toast.error(translate('web.errorInChangingPassword', 'Error In Changing Password'))
            }

        } catch (error) {
            setIsChangingPassword(false);
            toast.error(translate('web.errorInChangingPassword', 'Error In Changing Password'));
            console.error('error in Changing Password', error)
        }
    }

    useEffect(() => {
        const fetchCountriesAndCurrencies = async () => {
            await Promise.all([GetCurrencies(), getCountries()]);
        };
        fetchCountriesAndCurrencies();
    }, []); // This runs only once to fetch countries and currencies

    useEffect(() => {
        if (countries.length > 0) {
            getProfile(); // Run getProfile only when countries is populated
        }
    }, [countries]); // Runs whenever countries is updated


    return (
        profileLoaded &&
        <>
            <form className='row borderGray py-3 border-raduis-15-px' onSubmit={handleSubmit} >
                <h4 className='mainColor font-weight-bold' >{translate('web.basicInformation', 'Basic Information')}</h4>
                <div className='col-lg-6 col-md-6 col-sm-12 mt-2' >
                    <NetaqInput
                        type={'text'}
                        label={translate('web.fullName', 'Full Name')}
                        name={'fullName'}
                        placeholder={'Mohammad Ahmad'}
                        defaultValue={userProfile.fullName}
                        onChange={handleChange}
                    />
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12 mt-2' >
                    <NetaqInput
                        type={'text'}
                        label={translate('web.fullNameAr', 'Full Name Arabic')}
                        name={'fullNameAr'}
                        placeholder={'محمد أحمد'}
                        defaultValue={userProfile.fullNameAr}
                        onChange={handleChange}
                    />
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12 mt-2' >
                    <NetaqInput
                        type={'email'}
                        label={translate('web.email', 'Email')}
                        name={'email'}
                        placeholder={'mail@mail.com'}
                        defaultValue={userProfile.email}
                        required={true}
                        disabled={true}
                        onChange={handleChange}
                    />
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12 mt-2' >
                    <NetaqInput
                        type={'phone'}
                        label={translate('web.mobileNumber', 'Mobile Number')}
                        name={'PhoneNo'}
                        placeholder={'971xxxxxxxxx'}
                        required={true}
                        value={userProfile?.phone}
                        onChange={setPhoneNumber}
                        setIsPhoneNumberValid={setIsPhoneNumberValid}
                    />
                    {!isPhoneNumberValid &&
                        <div className='text-danger'>
                            {translate('web.phoneNumberIsNotValid', 'Phone Number Is Not Valid')}
                        </div>
                    }
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12 mt-2' >
                    <NetaqInput
                        type={'normalDate'}
                        label={translate('web.birthDate', 'birthDate')}
                        name={'birthDate'}
                        placeholder={'************'}
                        required={true}
                        value={userProfile?.birthDate}
                        onChange={handleChange}
                    />
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12 mt-2' >
                    <label className="capatalize_text">
                        {translate('web.gender', 'Gender')} :
                    </label>
                    <div className="row mx-0 rtl">
                        <div className="col-lg-6 col-md-6 col-sm-6 align-content-center">
                            <NetaqInput
                                type={"radio"}
                                label={translate('web.male', 'Male')}
                                name={"gender"}
                                value={1}
                                checked={userProfile?.gender == 1 ? true : false}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 align-content-center" >
                            <NetaqInput
                                type={"radio"}
                                label={translate('web.female', 'Female')}
                                name={"gender"}
                                value={2}
                                checked={userProfile?.gender == 2 ? true : false}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                    {profileLoaded &&
                        <NetaqInput
                            key={'nationalityId'}
                            type={"select"}
                            label={translate("web.nationality", "Nationality")}
                            placeholder={translate("web.nationality", "Nationality")}
                            name={"nationalityId"}
                            listSelect={countries}
                            value={nationality}
                            onChange={(item) => setNationality(item)}
                            required={true}
                        />
                    }
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                    {profileLoaded &&
                        <NetaqInput
                            key={'countryId'}
                            type={"select"}
                            label={translate("web.country", "Country")}
                            placeholder={translate("web.country", "Country")}
                            name={"countryId"}
                            listSelect={countries}
                            value={country}
                            onChange={(item) => setCountry(item)}
                            required={true}
                        />
                    }
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                    <NetaqInput
                        type={"text"}
                        label={translate("web.address", "Address")}
                        placeholder={translate("web.address", "Address")}
                        name={"address"}
                        key={"address"}
                        defaultValue={userProfile?.address}
                        onChange={handleChange}
                        required={true}
                    />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-2"></div>

                <div className='col-lg-2 col-md-4 col-sm-6 col-12 mx-auto mt-4 mb-2 text-center'>
                    {isUpdatingProfile ?
                        <Loader /> :
                        <CustomButton htmlText='Save' className='btn-success' key={'save'} type='submit' />
                    }
                </div>

            </form>
            <form className='row borderGray py-3 border-raduis-15-px my-2' onSubmit={handleSubmit} >
                <h4 className='mainColor font-weight-bold' >{translate('web.bankAccountDetails', 'Bank Account Details')}</h4>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                    <NetaqInput
                        type={"text"}
                        label={translate("web.bankName", "Bank Name")}
                        placeholder={translate("web.bankName", "Bank Name")}
                        name={"bankName"}
                        value={userProfile?.bankName}
                        key={"Bank Name"}
                        onChange={handleChange}
                        required={true}
                    />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                    <NetaqInput
                        type={"text"}
                        label={translate("web.iban", "IBAN")}
                        placeholder={translate("web.iban", "Iban")}
                        name={"bankAccount"}
                        key={"IBAN"}
                        onChange={handleChange}
                        value={userProfile?.bankAccount}
                        required={true}
                    />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                    <NetaqInput
                        key={'Currency'}
                        type={"select"}
                        label={translate("web.currency", " Currency")}
                        placeholder={translate("web.currency", " Currency")}
                        name={"currency"}
                        listSelect={currencies}
                        value={currency}
                        onChange={(item) => setCurreny(item)}
                        required={true}

                    />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                    {countries.length > 0 &&
                        <NetaqInput
                            key={'bankCountry'}
                            type={"select"}
                            label={translate("web.bankCountry", "Bank Country")}
                            placeholder={translate("web.bankCountry", "Bank Country")}
                            name={"bankCountry"}
                            listSelect={countries}
                            value={bankCountry}
                            onChange={(item) => setBankCountry(item)}
                            required={true}
                        />
                    }
                </div>

                <div className='col-lg-2 col-md-4 col-sm-6 col-12 mx-auto mt-4 mb-2 text-center'>
                    {isUpdatingProfile ?
                        <Loader /> :
                        <CustomButton htmlText='Save' className='btn-success' key={'save'} type='submit' />
                    }
                </div>

            </form>
            <form className='row borderGray py-3 border-raduis-15-px my-2' onSubmit={changePassword} >
                <h4 className='mainColor font-weight-bold' >{translate('web.changePassword', 'Change password')}</h4>
                <div className='col-lg-6 col-md-6 col-sm-12 mt-2' >
                    <NetaqInput
                        type={'password'}
                        label={translate('web.currentPassword', 'Current Password')}
                        name={'oldPassword'}
                        placeholder={'**********'}
                        required={true}
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                    />
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12 mt-2' >
                    <NetaqInput
                        type={'password'}
                        label={translate('web.newPassword', 'New Password')}
                        name={'newPassword'}
                        placeholder={'**********'}
                        required={true}
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                    />
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12 mt-2' >
                    <NetaqInput
                        type={'password'}
                        label={translate('web.confirmNewPassword', 'Confirm New Password')}
                        name={'confirmNewPassword'}
                        placeholder={'**********'}
                        required={true}
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    {((newPassword != confirmPassword) ?? false) &&
                        <span className='text-danger' > {translate('web.passwordsAreNotMatch', 'Passwords Are Not Match')} </span>
                    }
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12 mt-2' ></div>
                <div className='col-lg-2 col-md-4 col-sm-6 col-12 mx-auto mt-4 mb-2 text-center'>
                    {isChangingPassword ?
                        <Loader /> :
                        <CustomButton htmlText='Save' className='btn-success' key={'save'} type='submit' />
                    }
                </div>

            </form>
        </>
    )
}
