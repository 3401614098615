import React, { useEffect, useState } from 'react'
import axiosUmbraco from '../axios/axiosUmbraco';
const pageId = process.env.REACT_APP_RULES_ID;


export default function RulesPage({ toast }) {

    const [rulesData, setRulesData] = useState({});
    // get HomePage Data
    async function getRulesData() {
        try {
            const res = await axiosUmbraco.get(`/umbraco/delivery/api/v2/content/item/${pageId}`);
            console.debug('rules' , res.data.properties)
            setRulesData(res.data.properties);
        } catch (error) {
            console.error('error in Getting Home Page Data');
        }

    }
    useEffect(() => {
        getRulesData();
    }, [])

    return (
        !!rulesData?.terms?.markup && 
        <div style={{ marginTop: 50 }} dangerouslySetInnerHTML={{ __html: rulesData?.terms?.markup }} />
    )
}
