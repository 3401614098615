import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslate } from './TranslationText'

export default function OrganiserInfo({ data }) {
    const { translate } = useTranslate();
    return (
        <>
            <h2 className='roboto-light contentGray' >{translate('web.organizer', 'Organizer')}</h2>
            <p className='roboto-custom-black' >{data?.organizerName}</p>
            <div className='roboto-light contentGray' >{translate('web.website', 'Website')}</div>
            <Link to={`${data?.organizerWebsite}`} className='roboto-custom-black text-primary text-decoration-underline' >{data?.organizerWebsite}</Link>
            <div className='roboto-light contentGray mt-3' >{translate('web.email', 'Email')}</div>
            <Link to={`mailto:${data?.organizerEmail}`} className='roboto-custom-black text-primary text-decoration-underline' >{data?.organizerEmail}</Link>

        </>
    )
}
