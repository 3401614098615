import React, { useEffect, useState } from 'react'
import CompitionCard from './CompitionCard'
import axiosBackend from '../axios/axios';
import { useTranslate } from './TranslationText';

export default function CompetitionsSection({ showID }) {
    const [compitions, setCompitions] = useState([]);
    const { translate } = useTranslate();

    async function getCompitionCards() {
        try {
            const res = await axiosBackend.get(`/api/Show/GetShowChampionshipCards?id=${showID}`);
            setCompitions(res?.data?.result ?? []);
            console.log('champions', res.data)
        } catch (error) {
            console.error('error in getting Champions', error);
        }

    }

    useEffect(() => {
        getCompitionCards();
    }, [])

    return (
        <>
            <h2 className='roboto-custom-black' >{translate('web.championship', 'Championship')}</h2>
            <div className='row mx-0' >
                {compitions.length > 0 && compitions.map((item, index) => (
                    <CompitionCard data={item} key={`chamption${index}`} />
                ))}
            </div>
        </>
    )
}
