export function formatDate(str , isdash = false) {
    const date = new Date (str)
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    if(isdash){
      return `${year}-${month}-${day}`;
    }else{
      return `${day}/${month}/${year}`;
    }
  }