import React, { useState } from 'react'
import { useTranslate } from '../components/TranslationText';
import AccountSettingTab from '../components/AccountSettingTab';
import { useNavigate } from 'react-router';
import ParticipationHistory from '../components/ParticipationHistory';

export default function Profilepage({ toast }) {
    const { translate } = useTranslate();
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('profile');
    const userProfile = JSON.parse(localStorage.getItem('ecupUserProfile')) ?? {};
    const lang = localStorage.getItem('ecupLang') ?? 'en';

    const token = localStorage.getItem('accessTokenforECUP') ?? '';
    const isloggedInUser = !!token ? true : false;

    if (isloggedInUser == false) {
        navigate('/')
    }

    return (
        <div className='row' >
            <h4 className='mainColor font-weight-bold' >{translate('web.welcome', 'Welcome')} {lang == 'en' ? userProfile.fullName : userProfile.fullNameAr}</h4>
            <hr />
            <div className='col-lg-3 col-md-3 col-sm-12 col-12' >
                <div className='customTabsContainer w-100 position-relative' style={{ borderBottom: 0 }}>
                    <div className={`customTab roboto-light w-100 contentGray rounded ${activeTab == 'profile' && 'customActive'}`} style={{ fontWeight: 'bold' }} onClick={() => setActiveTab('profile')} >{translate('web.accountSetting', 'Account Setting')}</div>
                    {activeTab == 'profile' &&
                        <div className='position-absolute' style={{ top: 3, right: -15 }}>
                            <i className="fa fa-arrow-right fa-lg text-success" aria-hidden="true"></i>
                        </div>
                    }
                </div>
                <div className='customTabsContainer w-100 position-relative mt-2' style={{ borderBottom: 0 }}>
                    <div className={`customTab roboto-light w-100 contentGray rounded ${activeTab == 'participationHistory' && 'customActive'}`} style={{ fontWeight: 'bold' }} onClick={() => setActiveTab('participationHistory')} >{translate('web.participationHistory', 'Participation History')}</div>
                    {activeTab == 'participationHistory' &&
                        <div className='position-absolute' style={{ top: 3, right: -15 }}>
                            <i className="fa fa-arrow-right fa-lg text-success" aria-hidden="true"></i>
                        </div>
                    }
                </div>
            </div>

            <div className='col-lg-9 col-md-9 col-sm-12 col-12' >
                {activeTab == 'profile' &&
                    <AccountSettingTab key={'accountSetting'} toast={toast} />
                }
                {activeTab == 'participationHistory' &&
                    <ParticipationHistory key={'participationHistory'} toast={toast} />
                }
            </div>
        </div>
    )
}
