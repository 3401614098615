import React from 'react'

export default function HomePageOverrideStyles() {
    return (
        <style>
            {` .hidden{
 display:none; 
}
.pointer {cursor: pointer;}
.seasonCardsContainer {

justify-content: space-around !important;
}
.newsImageContainer>img {
/* height: 170px; */
height:auto !important;
}
.newsPost {
width: 60%;
min-height: 170px;
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: space-between;
padding-left: 10px;
height: unset;
}
.newsCard {
min-height: 194px;
width: 100%;
display: flex;
flex-direction: row;
height: unset;
}
.ltr{
direction: ltr !important;
}
.flagimgandcalandericon>img {
/* width: 56px; */
/* height: 56px; */
height: 40px !important;
width: 60px !important;
line-height: 1.42857143;
background-color: #fff;
border: 1px solid #ddd;
border-radius: 4px;
padding: 2px;
}
.newsPostTitle {
margin-bottom: 6px;
}
.newsPostDesc {
margin-bottom: 6px;
}
.seasonspacer {
min-height: 83px;
height: 50%;
width: 1px;
background: var(--gray);
margin: auto 13px;
}
.aboutTextContainer p {
font-family: 'Roboto' !important;
}

.aboutTextContainer span {
font-family: 'Roboto' !important;
}
.seasonCard {

overflow: hidden;
}
.aboutImageContainer>img {
width: 95% !important;
}
.SliderRightSide video {
height: 450px;
}
.SliderRightSide {
width:80%;
}
.SliderLeftSide{
width:50%;
}
.SliderLeftSide {
height: 90vh;
}
.SliderRightSide, .SliderLeftSide {

height: 90vh;

}
.secondSectionContainer{

padding-top:0px;
}

@media (max-width: 943px) {
.secondSectionContainer {
min-height: unset;
}
.thirdSectionContainer{
margin-top: 310px !important;
}
.aboutImageContainer, .aboutTextContainer {
margin-top: 60px;
}
.headerLogo{
margin: 0 5px;
}

}

@media (max-width: 520px) {
.secondSectionContainer {
min-height: unset; 
}
.SliderRightSide video {
height: 210px;
}
.SliderRightSide, .SliderLeftSide {
height: 25vh;
min-height: unset;
width: 100%;
}
.firstSliderContainer {
margin-top: 20px;
}
.SliderTitle, .lablelTitle, .seasonSectionTitle {
font-size: 35px;
}
.SliderLeftSide {
width: 100%;
}
div#CustomSliderTitle {
margin-top: 0px;
}
.aboutImageContainer, .aboutTextContainer {
margin-top: 0px;
}
}


</style>
<style>
@media (max-width: 943px) {
.BurgerIcon{
right:5px;
}
}`}
        </style>
    )
}
