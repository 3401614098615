import React from 'react'
import { formatDate } from '../helper/formatDate'
import { useNavigate } from 'react-router'

export default function HorseCard({ horse, onClick, isSelectedTab = false }) {
    const navigate = useNavigate();
    console.debug('horse is ', horse)
    debugger
    return (
        <div className='horseCard bgGray my-1'>
            <div className='w-100' >
                <img src='./svgs/horse-head-solid.svg' style={{ height: 15 }} /> {horse?.regNr} - {horse?.name}
            </div>
            <div className='w-100' ><i className="fa fa-calendar me-1"></i> {formatDate(horse.birthDate)}</div>
            <div className='w-100' ><i className="fa fa-user me-2"></i> {horse?.ownerName}</div>
            <div className='w-100' ><img src='./svgs/earth-americas-solid.svg' className='me-1' style={{ height: 15 }} /> {horse.origin}</div>
            {horse.isSelected == false && (horse.status == "" || horse.status.toLowerCase() == 'draft') &&
                <div style={{ position: 'absolute', top: 10, right: 15, cursor: 'pointer' }} onClick={onClick} ><i className="fa fa-plus"></i></div>
            }
            {(horse.status.toLowerCase() == 'returned' || (horse.status == "" || horse.status.toLowerCase() == 'draft')) &&
                <div style={{ position: 'absolute', bottom: 10, right: 12, cursor: 'pointer' }} onClick={() => navigate(`/addNewHorse?isEdit=true&horseId=${horse.id}`)} ><i className="fa fa-edit"></i></div>
            }
            {isSelectedTab && (horse.status == "" || horse.status.toLowerCase() == 'draft') &&
                <div style={{ position: 'absolute', top: 10, right: 15, cursor: 'pointer' }} onClick={onClick} ><i className="fa fa-trash"></i></div>
            }
        </div>
    )
}
