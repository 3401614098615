import React from 'react'
import { useNavigate } from 'react-router'
import HomePageImageVideo from '../components/HomePageImageVideo';
import AboutSection from '../components/AboutSection';
import SeasonSection from '../components/SeasonSection';
import NewsSection from '../components/NewsSection';
import HomePageOverrideStyles from '../components/HomePageOverrideStyles';
import { Link } from 'react-router-dom';
import { useTranslate } from '../components/TranslationText';

export default function HomePage({ data }) {

    const { translate } = useTranslate();

    const slides = data?.slides ?? [];



    return (
        <div>
            <HomePageOverrideStyles key={'ovverrideStyles'} />
            <HomePageImageVideo key={'homeFirstSection'} slides={slides} />
            <AboutSection key={'aboutSection'} data={data} />
            <SeasonSection key={'seasonSection'} />
            <NewsSection key={'newsSection'} />
            <div className="contactRow roboto-light mt-4" id='aboutSection' >
                {translate('web.TOHOSTASHOWPLEASECONTACTUSAT', 'TO HOST A SHOW, PLEASE CONTACT US AT')} &nbsp;
                <Link to={`mailto: ${data?.email}`} className="contactEmail roboto-regular">
                    {data?.email}
                </Link>
            </div>
        </div>
    )
}
