import React, { useEffect, useState } from 'react'
import HorseCard from '../components/HorseCard'
import NetaqInput from '../components/NetaqInput'
import CustomButton from '../components/CustomButton';
import { useLocation, useNavigate } from 'react-router';
import axiosBackend from '../axios/axios';
import { useTranslate } from '../components/TranslationText';
import { splitCamelCase } from '../helper/SplitCamelCase';
import Popup from 'reactjs-popup';
import { convertToCamelCase } from '../helper/convertToCamelCase';

export default function ShowEntry({ toast }) {
    const navigate = useNavigate();
    const { translate } = useTranslate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const showID = searchParams.has("id")
        ? searchParams.get("id")
        : false;
    const [showDetails, setShowDetails] = useState({});
    const userProfile = JSON.parse(localStorage.getItem('ecupUserProfile')) ?? {};
    const [activeTab, setActiveTab] = useState('draft');
    const [horseSearchValue, setHorseSearchValue] = useState('');
    const [maxAllowedHorses, setMaxAllowedHorses] = useState(10);
    const [horses, setHorses] = useState([]);
    const currentLanguage = localStorage.getItem('ecupLang') ?? 'en';
    const [acceptedTermsAndConditions, setAcceptedTermsAndConditions] = useState(false);

    const uniqueCategories = ['draft', ...Array.from(new Set(horses.filter((x) => x.status != "" && x.status?.toLowerCase() != "draft").map(horse => horse.status)))];

    const statusToCount = ['pendingreview', 'waitinglist', 'completed'];


    // get all Horses 
    async function getAllOwnedHorses() {
        try {
            const res = await axiosBackend.get(`/api/ShowEntry/GetShowEntryOwnerHorses?userId=${userProfile?.id}&showId=069a54d8-251f-4a18-5b16-08dcfdf44ad6`);

            if (res.status == 200) {
                if (res.data.responseCode == "200") {
                    const formatedData = res.data.horsesList.map((horse) => ({
                        isSelected: !!!horse?.showHorseStatus ? false : true,
                        name: horse.name,
                        regNr: horse.registerNumber,
                        ownerName: horse.owner,
                        origin: horse.origin,
                        id: horse.id,
                        status: horse?.showHorseStatus,
                        birthDate: horse.birthDate
                    }));
                    setHorses(formatedData);
                }
                else {
                    toast.error(translate('web.errorInGettingHorses', 'Error In Getting Horses'))
                }
            }

        } catch (error) {
            toast.error(translate('web.errorInGettingHorses', 'Error In Getting Horses'))
            console.error('horsrs are', error)

        }
    }
    // get show Details
    async function getShowDetails() {
        try {
            const res = await axiosBackend.get(`api/Show/GetShowEntrybyId?id=${showID}`);
            if (res.status == 200) {
                if (res.data.responseCode == "200") {
                    setShowDetails(res.data.result);
                    setMaxAllowedHorses(res.data.result.maxHorseNumberPerOwner ?? -1)
                }
                else {
                    toast.error(translate('ErrorInLoadingShowDetails', 'Error In Loading Show Details'))
                }
            } else {
                toast.error(translate('ErrorInLoadingShowDetails', 'Error In Loading Show Details'));
            }
        } catch (error) {
            toast.error(translate('ErrorInLoadingShowDetails', 'Error In Loading Show Details') + ' ' + error);

        }

    }


    function addRemoveHorse(id, isRemovingCall = false) {
        const maxReached = (horses.filter((x) => x.isSelected == true || statusToCount.includes(x.status)).length >= maxAllowedHorses) && (maxAllowedHorses > 0);
        if (maxReached && !isRemovingCall) {
            toast.error('you Have Reached The maximum Horses to add');
            return;
        }
        setHorses((prev) => {
            return prev.map(horse =>
                horse.id === id ? { ...horse, isSelected: !horse.isSelected } : horse
            );
        });
    }

    async function submitHorses(isDraft = true) {
        if (isDraft) {
            try {
                const res = await axiosBackend.post('/api/ShowEntry/CreateShowEntry', {
                    showId: showID,
                    createdBy: userProfile.id,
                    ownerId: userProfile.id,
                    isDraft: true,
                    showParticipantsHorsesList: horses.filter((x) => x.isSelected == true && (x.status.toLowerCase() == 'draft' || x.status == "")).map((horse) => ({
                        horseId: horse.id,
                        partictedOwnerId: userProfile.id,
                        isRequiredConfirmation: true
                    }))
                })
                if (res.status == 200) {
                    if (res.data.responseCode == "200") {
                        toast.warn(translate('web.horseIsAddedToDraft', 'Horse Is Added To Draft'));
                        getAllOwnedHorses();
                    }
                } else {
                    toast.error(translate('web.failedToSetHorseAsDraft', 'Failed To Set Horse AsDraft'));
                }
            } catch (error) {
                toast.error(translate('web.failedToSetHorseAsDraft', 'Failed To Set Horse AsDraft'));
                console.error('Failed To Set Horse AsDraft', error);

            }

        } else {
            try {
                const res = await axiosBackend.post('/api/ShowEntry/CreateShowEntry', {
                    showId: showID,
                    createdBy: userProfile.id,
                    ownerId: userProfile.id,
                    isDraft: false,
                    showParticipantsHorsesList: horses.filter((x) => x.isSelected == true && (x.status.toLowerCase() == 'draft' || x.status == "")).map((horse) => ({
                        horseId: horse.id,
                        partictedOwnerId: userProfile.id,
                        isRequiredConfirmation: true
                    }))
                })
                if (res.status == 200) {
                    if (res.data.responseCode == "200") {
                        toast.success(translate('web.yourRequestIsAddedSuccessfully', 'Your Request Is Added Successfully'));
                        getAllOwnedHorses();
                        setActiveTab('PendingConfirmation')
                    }
                } else {
                    toast.error(translate('web.failedToSubmitRequest', 'Failed To Submit Request'));
                }
            } catch (error) {
                toast.error(translate('web.failedToSubmitRequest', 'Failed To Submit Request'));
                console.error('Failed To Set Horse AsDraft', error);

            }
        }

    }


    // on Page Load
    useEffect(() => {
        getShowDetails();
        getAllOwnedHorses();
    }, [])


    return (
        <div className='row'>
            {/* <h2 className='roboto-light contentGray' >{showDetails?.showName}</h2> */}
            <h2 className='roboto-custom-black col-sm-10 col-12 fw-bold' >{showDetails?.showName}</h2>
            <div className='col-sm-2 col-12' >
                <CustomButton key={'submit'} type='submit' htmlText={`<i class="fa fa-plus"></i> ${translate('web.addNewHorse', 'Add New Horse')}`} className='btn-success' onClick={() => navigate('/addNewHorse')} />
            </div>
            <div className='col-lg-3 col-md-4 col-sm-12 max-height-80-vh' >
                <div className='w-100 mb-3'>
                    <NetaqInput
                        key={'horseSearch'}
                        defaultValue={horseSearchValue}
                        placeholder={translate('web.searchByNameOrRegNumber', 'Search By Name Or Reg. Number')}
                        name={'horseSearch'}
                        onChange={(e) => setHorseSearchValue(e.target.value)}
                        type={'text'}

                    />
                </div>
                {horses.filter((item) => {
                    const searchValue = horseSearchValue.toLowerCase();
                    return (
                        searchValue === "" ||
                        item.regNr.toString().includes(searchValue) ||
                        item.name.toLowerCase().includes(searchValue)
                    );
                }).map((horse, index) => (
                    <HorseCard key={`horse${index}`} horse={horse} onClick={() => { addRemoveHorse(horse.id); setActiveTab('draft') }} />
                ))}
            </div>
            <div className='col-lg-9 col-md-8 col-sm-12 row  align-content-start' >
                {maxAllowedHorses > 0 &&
                    <h4 className='roboto-custom-black' >{translate('web.youCanParticipatewithin', 'You Can Participate within')} {maxAllowedHorses} {translate('web.horse', 'Horses')} ({horses.filter((x) => x.isSelected == true || statusToCount.includes(x.status)).length} / {maxAllowedHorses})</h4>
                }
                <div className='customTabsContainer'>
                    {uniqueCategories.map((item, index) => (
                        <div className={`customTab roboto-light contentGray ${activeTab == item && 'customActive'} ${index > 0 && 'ms-1'} `} onClick={() => setActiveTab(item)} >{translate(`web.${convertToCamelCase(item)}`, convertToCamelCase(item))}</div>
                    ))}
                </div>
                {horses.filter((x) => x.isSelected == true && (x.status.toLowerCase() == activeTab || x.status == "")).length > 0 && activeTab == 'draft' &&
                    <div className='row borderGray mx-0 my-2 py-2 px-1 border-raduis-15-px' >
                        {horses.filter((x) => x.isSelected == true && (x.status.toLowerCase() == activeTab || x.status == "")).map((horse, index) => (
                            <div className='col-md-4 col-sm-6 col-12'>
                                <HorseCard key={`horse${index}`} horse={horse} onClick={() => addRemoveHorse(horse.id, true)} isSelectedTab={true} />
                            </div>

                        ))}
                    </div>
                }
                {horses.filter((x) => x.status == activeTab && x.status != 'draft').length > 0 &&
                    <div className='row borderGray mx-0 my-2 py-2 px-1 border-raduis-15-px' >
                        {horses.filter((x) => x.status == activeTab && x.status != 'draft').map((horse, index) => (
                            <div className='col-md-4 col-sm-6 col-12'>
                                <HorseCard key={`horse${index}`} horse={horse} onClick={() => addRemoveHorse(horse.id)} isSelectedTab={true} />
                            </div>

                        ))}
                    </div>
                }
                {horses.filter((x) => x.isSelected == true && (x.status.toLowerCase() == activeTab || x.status == "")).length > 0 &&
                    <div className='row mx-0'>
                        <div className='col-sm-3 col-5 ms-auto'>
                            <CustomButton key={'submit'} type='navigate' htmlText={`<i class="fa fa-file"></i> ${translate('web.saveAsDraft', 'Save As Draft')}`} className='btn-light bg-white text-success border-success' onClick={() => submitHorses(true)} />
                        </div>
                        <Popup
                            trigger={
                                <div className='col-sm-2 col-5 ms-1'>
                                    <CustomButton key={'submit'} type='submit' htmlText={`<i class="fa fa-check"></i> ${translate('web.submit', 'Submit')}`} className='btn-success' />
                                </div>
                            }
                            modal
                            lockScroll={true}
                            closeOnDocumentClick={false}
                            closeOnEscape
                            contentStyle={{ background: 'rgba(0,0,0,0)' }}
                            overlayStyle={{ background: 'rgba(0,0,0,0.2)' }}
                        >
                            {(close) =>
                                <div className='container bg-white borderGray rounded' style={{ maxWidth: 1100 }} >
                                    <div className='row py-2 px-2' >
                                        <h4 className='text-success text-center' >{translate('web.termsAndConditions', 'Terms And Conditions')}</h4>
                                        <div className='col-12' dangerouslySetInnerHTML={{ __html: showDetails?.termsAndConditions }} />
                                        <NetaqInput type={'checkbox'} name={'termsAndConditions'} onChange={(e) => setAcceptedTermsAndConditions(e.target.checked)} label={translate('web.iHaveAgreedOnTermsAndConditions', 'I Have Agreed On Terms And Conditions')} />
                                        <div className='col-2 me-auto mt-2 text-center'>
                                            <CustomButton key={'submit'} type='submit' htmlText={`<i class="fa fa-ban"></i> ${translate('web.cancel', 'Cancel')}`} className='btn-secondary' onClick={close} />
                                        </div>
                                        <div className='col-2 mt-2 ms-auto text-center'>
                                            <CustomButton key={'submit'} type='submit' htmlText={`<i class="fa fa-check"></i> ${translate('web.submit', 'Submit')}`} className='btn-success' disabled={!acceptedTermsAndConditions} onClick={() => submitHorses(false)} />
                                        </div>
                                    </div>
                                </div>
                            }
                        </Popup>
                    </div>
                }
            </div>
        </div>
    )
}
