import React, { useEffect, useState } from 'react'
import '../commonCss/customtabs.css'
import axiosBackend from '../axios/axios';
import { useTranslate } from './TranslationText';
import { convertToCamelCase } from '../helper/convertToCamelCase';
export default function JudgesList() {
    const { translate } = useTranslate();
    const [judges, setjudges] = useState([]);
    const [activeTab, setActiveTab] = useState('');
    const uniqueCategories = Array.from(new Set(judges.map(judge => judge.category)));

    const sortOrder = ['Judge', 'DC', 'RingMaster'];

    uniqueCategories.sort((a, b) => {
        const indexA = sortOrder.indexOf(a);
        const indexB = sortOrder.indexOf(b);

        // If both items are in the sortOrder array, sort by their index in sortOrder
        if (indexA !== -1 && indexB !== -1) {
            return indexA - indexB;
        }
        // If only one item is in the sortOrder array, it should come first
        if (indexA !== -1) return -1;
        if (indexB !== -1) return 1;
        // If neither item is in sortOrder, maintain their original order
        return 0;
    });

    async function getJuges() {
        try {
            const res = await axiosBackend.get('/api/Show/GetShowJudges?id=069a54d8-251f-4a18-5b16-08dcfdf44ad6');
            if (res.status == 200) {
                if (res.data.responseCode == "200") {
                    const formatedData = res.data.result.map((judge) => ({
                        name: judge?.name,
                        letter: judge?.letter ?? '',
                        imgSrc: judge?.countryFlag ?? '',
                        category: judge?.role
                    }));
                    setjudges(formatedData);
                }
            }
        } catch (error) {

        }
    }



    useEffect(() => {
        getJuges();
    }, []);
    useEffect(() => {
        if (uniqueCategories.length > 0) {
            setActiveTab(uniqueCategories[0])
        }
    }, [judges])


    return (
        <div className='my-2'>
            <div className='customTabsContainer'>
                {uniqueCategories.map((item, index) => (
                    <div className={`customTab roboto-light contentGray ${activeTab == item && 'customActive'} ${index > 0 && 'ms-1'} `} onClick={() => setActiveTab(item)} >{translate(`web.${convertToCamelCase(item)}`, item)}</div>
                ))}
            </div>
            <div className='customTabContent px-3 py-3' >
                {judges.filter((item) => item.category == activeTab).map((judge) => (
                    <div className='mt-2 d-flex align-items-center ' >
                        <span className='roboto-custom-black' style={{ fontWeight: 'bold', minWidth: 20 }} >{judge.letter}</span>
                        <img className='ms-2' style={{ maxWidth: 50, width: '100%' }} src={judge.imgSrc} alt='Egypt' />
                        <span className='ms-2 roboto-custom-black'  > {judge.name} </span>
                    </div>
                ))}

            </div>
        </div>
    )
}
