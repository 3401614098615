import React, { useState } from 'react'
import { useTranslate } from '../components/TranslationText'
import RegisterForm from '../components/RegisterForm';
import OTPForm from '../components/OTPForm';
import axiosBackend from '../axios/axios';
import { useNavigate } from 'react-router';

export default function RegisterPage({ toast }) {
    const { translate } = useTranslate();
    const [showOTP, setShowOTP] = useState(false);
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const [otp, setOTP] = useState();
    const [email, setEmail] = useState('');

    async function login() {
        try {
            const login = await axiosBackend.post('/api/Account/login', { email: email, password: password })

            if (login.status == 200) {
                if (login.data.responseCode == '200') {
                    const token = login.data.token ?? "";
                    const userProfile = JSON.stringify(login.data);
                    localStorage.setItem('accessTokenforECUP', token);
                    localStorage.setItem('ecupUserProfile', userProfile);
                    toast.success(translate('web.loggedInSuccessfully', 'Logged In Successfully'));
                    setTimeout(() => {
                        navigate('/profile');
                    }, 3000);
                } else {
                    toast.error(translate('web.invalidUserNameOrPassword', 'Invalid User Name Or Password'));
                }

            } else {
                toast.error(translate('web.invalidUserNameOrPassword', 'Invalid User Name Or Password'));

            }
        } catch (error) {
            toast.error(translate('web.cannotLoginPleaseContactTheAdmin', 'Cannot Login Please Contact The Admin'));
        }

    }

    const sendOTP = async (e) => {
        e.preventDefault();
        try {
            const res = await axiosBackend.post('/api/Account/complete-register', {
                otp: otp,
                email: email
            })
            if (res.status == 200) {
                toast.success(translate('web.yourAccountIsVerified', 'Your Account Is Verified'));
                await login();
            } else {
                toast.error(translate('web.errorInSendingOTPPleaseContactTheAdmin', 'Error In Sending OTP Please Contact The Admin'));
            }
        } catch (error) {
            console.error('error in Sending OTP', error);
            toast.error(translate('web.errorInSendingOTPPleaseContactTheAdmin', 'Error In Sending OTP Please Contact The Admin'));
        }
    }
    return (
        <>
            <h2>{translate('web.registerNewAccount', 'Register New Account')}</h2>
            <RegisterForm key={'registerForm'} toast={toast} setShowOTP={setShowOTP} setEmail={setEmail} setPassword={setPassword} />
            {showOTP &&
            <div className='OTPOverLay'>
                <div className='LoginFormContainer'>
                    <OTPForm key={'otpForm'} setOTP={setOTP} onSubmit={sendOTP} isRegisterPage={true} setShowOTP={setShowOTP} />
                </div>
            </div>
            }
        </>
    )
}
