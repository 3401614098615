import React, { useEffect, useState } from 'react';
import { useTranslate } from './TranslationText';
import NetaqInput from "./NetaqInput";
import CustomButton from './CustomButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axiosBackend from '../axios/axios';
import { formatDate } from '../helper/formatDate';
import Loader from './Loader';


export default function ParticipationHistory({ toast }) {
    const { translate } = useTranslate();
    const itemsPerPage = 5;
    const [totalCount, setTotalCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [filterKeys, setFilterKeys] = useState({
        horseName: '',
        showName: '',
        startDate: null,
        endDate: null,
        skip: 0,
        take: itemsPerPage,
    });
    function createTableData(
        horseName = "",
        showName = "",
        showRank = "",
        showDate = "",
        categoryOrClass = "",
        rank = "",
        medal = "",

    ) {
        return { horseName, showName, showRank, showDate, categoryOrClass, rank, medal };
    }
    const [participationHistory, setParticipationHistory] = useState([
    ]);

    async function getParticipationData() {
        setIsLoading(true);
        try {
            const res = await axiosBackend.post('/api/ShowEntry/FilterShowParticipantHistory', { ...filterKeys, startDate: filterKeys?.startDate == "" ? null : filterKeys?.startDate, endDate: filterKeys?.endDate == "" ? null : filterKeys?.endDate });
            console.debug('participationData', res.data);
            if (res.status == 200) {
                if (res.data.responseCode == "200") {
                    setParticipationHistory(res?.data?.result.map((item) => (
                        createTableData(item.horseName, item.showName, item.showRank, formatDate(item.showStartDate), item.categoryClassName, item.rank, item.medal)
                    )));
                    setTotalCount(res?.data?.totalCount ?? 0);
                    setIsLoading(false);

                } else {
                    toast.error(translate('web.errorInGettingParticpateHistory', 'Error In Getting Particpate History'));
                    setIsLoading(false);
                }
            } else {
                toast.error(translate('web.errorInGettingParticpateHistory', 'Error In Getting Particpate History'));
                setIsLoading(false);
            }
        } catch (error) {
            toast.error(translate('web.errorInGettingParticpateHistory', 'Error In Getting Particpate History'));
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getParticipationData();
    }, [currentPage])

    const handleNext = () => {
        if (currentPage * itemsPerPage < totalCount) {
            setCurrentPage(prev => prev + 1);
            setFilterKeys(prev => ({
                ...prev,
                skip: prev.skip + itemsPerPage,
            }));
        }
    };

    const handlePrevious = () => {
        if (currentPage > 1) {
            setCurrentPage(prev => prev - 1);
            setFilterKeys(prev => ({
                ...prev,
                skip: prev.skip - itemsPerPage,
            }));
        }
    };

    const handleFilterChange = (e) => {
        setFilterKeys((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
            skip: 0,
        }))
    }
    const handleFilterReset = () => {
        setFilterKeys({
            horseName: '',
            showName: '',
            startDate: "",
            endDate: "",
            skip: 0,
            take: itemsPerPage,
        });
    }

    const handleFilterSubmit = () => {
        getParticipationData();
    }

    return (
        <div className='row borderGray py-3 border-raduis-15-px'>
            <h4 className='mainColor font-weight-bold' >{translate('web.participationHistory', 'Participation History')}</h4>
            <div className='col-lg-2 col-md-2 col-sm-2 col-6'>
                <NetaqInput
                    type={'text'}
                    name={'showName'}
                    label={translate('web.showName', 'Show Name')}
                    onChange={handleFilterChange}
                    placeholder={translate('web.typeHere', 'Type Here')}
                    value={filterKeys?.showName}
                />
            </div>
            <div className='col-lg-2 col-md-2 col-sm-2 col-6'>
                <NetaqInput
                    type={'text'}
                    name={'horseName'}
                    label={translate('web.horseName', 'Horse Name')}
                    onChange={handleFilterChange}
                    placeholder={translate('web.typeHere', 'Type Here')}
                    value={filterKeys?.horseName}

                />
            </div>
            <div className='col-lg-2 col-md-2 col-sm-2 col-6'>
                <NetaqInput
                    type={'normalDate'}
                    name={'startDate'}
                    onChange={handleFilterChange}
                    label={translate('web.from', 'From')}
                    value={filterKeys?.startDate}
                />
            </div>
            <div className='col-lg-2 col-md-2 col-sm-2 col-6'>
                <NetaqInput
                    type={'normalDate'}
                    name={'endDate'}
                    onChange={handleFilterChange}
                    label={translate('web.to', 'To')}
                    value={filterKeys?.endDate}
                />
            </div>
            <div className='col-lg-2 col-md-2 col-sm-2 col-6 d-flex'>
                <CustomButton type='navigate' onClick={handleFilterReset} htmlText={translate('web.reset', 'Reset')} className='btn-light bg-white text-success border-success' containerClass='mt-auto w-100' key={'reset'} />
            </div>
            <div className='col-lg-2 col-md-2 col-sm-2 col-6 d-flex'>
                <CustomButton type='submit' htmlText={translate('web.submit', 'Submit')} onClick={handleFilterSubmit} className='btn-success' containerClass='mt-auto w-100' key={'reset'} />
            </div>
            {
                <div className='col-12 mt-3' >
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead className='bg-success'>
                                <TableRow >
                                    <TableCell width={150} className='text-white'>{translate('web.horseName', 'Horse Name')}</TableCell>
                                    <TableCell width={150} className='text-white' align="right">{translate('web.showName', 'Show Name')}</TableCell>
                                    <TableCell className='text-white' align="right">{translate('web.showRank', 'Show Rank')}</TableCell>
                                    <TableCell className='text-white' align="right">{translate('web.showDate', 'Show Date')}</TableCell>
                                    <TableCell className='text-white' align="right">{translate('web.categoriesOrClasses', 'Categories Or Classes')}</TableCell>
                                    <TableCell className='text-white' align="right">{translate('web.rank', 'Rank')}</TableCell>
                                    <TableCell className='text-white' align="right">{translate('web.medal', 'Medal')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!isLoading ?
                                    <>
                                        {participationHistory.length > 0 ? participationHistory.map((row) => (
                                            <TableRow
                                                key={row.horseName}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell width={150} component="th" scope="row">
                                                    {row.horseName}
                                                </TableCell>
                                                <TableCell width={150} align="right">{row.showName}</TableCell>
                                                <TableCell align="right">{row.showRank}</TableCell>
                                                <TableCell align="right">{row.showDate}</TableCell>
                                                <TableCell align="right">{row.categoryOrClass}</TableCell>
                                                <TableCell align="right">{row.rank}</TableCell>
                                                <TableCell align="right">{row.medal}</TableCell>
                                            </TableRow>
                                        )) :
                                            <TableRow
                                                key={'nodata'}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell colSpan={7} className='text-center text-danger fw-bold'>
                                                    {translate('web.thereIsNoData', 'There Is No Data')}
                                                </TableCell>
                                            </TableRow>
                                        }
                                    </>
                                    :
                                    <TableRow
                                        key={'loader'}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell colSpan={7} className='text-center'>
                                            <Loader />
                                        </TableCell>
                                    </TableRow>
                                }


                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div className="d-flex justify-content-between mt-3">
                        <button
                            className={`btn ${currentPage === 1 ? 'btn-secondary disabled' : 'btn-success'}`}
                            onClick={handlePrevious}
                        >
                            {translate('web.previous', 'Previous')}
                        </button>
                        <button
                            className={`btn ${(currentPage * itemsPerPage >= totalCount) ? 'btn-secondary disabled' : 'btn-success'}`}
                            onClick={handleNext}
                        >
                            {translate('web.next', 'Next')}
                        </button>
                    </div>
                </div>

            }
        </div>
    )
}
