import React, { useEffect, useState } from 'react'
import NetaqInput from './NetaqInput'
import { useTranslate } from './TranslationText';
import SimpleFileUpload from './SimpleFileUpload';
import CustomButton from './CustomButton';
import axiosBackend from '../axios/axios';
import { convertToCamelCase } from '../helper/convertToCamelCase';
import Loader from '../components/Loader';
import { useLocation, useNavigate } from 'react-router';

export default function AddNewHorseForm({ toast }) {
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const isEdit = searchParams.has("isEdit")
        ? searchParams.get("isEdit") == 'true'
        : false;
    const horseId = searchParams.has("horseId")
        ? searchParams.get("horseId")
        : false;

    const showID = '069a54d8-251f-4a18-5b16-08dcfdf44ad6';
    const userProfile = JSON.parse(localStorage.getItem('ecupUserProfile')) ?? {};
    const [isAdding, setIsAdding] = useState(false);
    const { translate } = useTranslate();
    const [passportFiles, setPassportFiles] = useState([]);
    const [ownerShipProf, setOwnerShipProf] = useState([]);
    const [qualificationCertificate, setQualificationCertificate] = useState([]);
    const currentLanguage = localStorage.getItem('ecupLang') ?? 'en';
    const [countries, setCountries] = useState([]);
    const [colors, setColors] = useState([]);
    const [genders, setGenders] = useState([]);
    const [horseColor, setHorseColor] = useState([]);
    const [horseGender, setHorseGender] = useState([]);
    const [origin, setOrigin] = useState([]);
    const [horseCountry, setHorseCountry] = useState([]);
    const [qualificationCountry, setQualificationCountry] = useState([]);
    const [isOwnedByMe, setCheckHorse] = useState(false);

    const [horseData, setHorseData] = useState({
        nameEn: "",
        nameAr: "",
        registrationNumber: "",
        birthDate: "",
        horseUELN: "",
        breederNameEn: "",
        breederNameAr: "",
        sireNameEn: "",
        isOwnedByMe: true,
        sireNameAr: "",
        damNameEn: "",
        damNameAr: "",
        address: "",
        showName: "",
        showDate: "",
        className: "",
        classRank: "",
        referenceWebsiteUrl: "",
    });





    const handleChange = (e) => {
        if (e.target.type == 'checkbox') {
            setHorseData((prev) => ({
                ...prev,
                [e.target.name]: e.target.checked
            }));
        }
        else {
            setHorseData((prev) => ({
                ...prev,
                [e.target.name]: e.target.value
            }));
        }
    }

    const handleFilesUploaded = (e, type) => {
        if (e.target.files && e.target.files.length > 0) {
            let checkFileSize = true;
            const fileListHook = [];

            for (let fileIndex = 0; fileIndex < e.target.files.length; fileIndex++) {
                if (e.target.files[fileIndex].size > 10 * 1024 * 1024) {
                    checkFileSize = false;
                    toast.error(translate("web.fileSizeLimit", "File size 10MB limit."));
                } else {
                    // Check allowed file types
                    const file = e.target.files[fileIndex];
                    const allowedMimeTypes = ['image/', 'application/pdf'];
                    if (!allowedMimeTypes.some(type => file.type.startsWith(type))) {
                        toast.error(translate("web.invalidFileType", "Only images and PDFs allowed."));
                        continue; // Skip to the next file if type is not allowed
                    }
                    fileListHook.push({
                        documentFile: URL.createObjectURL(e.target.files[fileIndex]),
                        name: e.target.files[fileIndex].name,
                        file: e.target.files[fileIndex],
                    });
                }
            }
            if (type == 'passport') {
                setPassportFiles(fileListHook);
            }
            if (type == 'ownerShipProf') {
                setOwnerShipProf(fileListHook);
            }
            if (type == 'qualificationCertificate') {
                setQualificationCertificate(fileListHook);
            }

        }
    };

    async function handleSubmit(e) {

        e.preventDefault();
        for (const key in horseData) {
            if (horseData.hasOwnProperty(key)) {

                if (!!!horseData[key]) {
                    toast.error(translate('web.theField', 'The Field') + " " + translate(`web.${convertToCamelCase(key)}`, key) + " " + translate('web.isRequired', 'Is Required'))
                    return;
                }
            }
        }
        if (passportFiles.length <= 0) {
            toast.error(translate('web.thePassportIsRequired', 'Passport Is Required'))
            return;
        }
        if (ownerShipProf.length <= 0) {
            toast.error(translate('web.theownerShipProfIsRequired', 'OwnerShip Prof Is Required'))
            return;
        }
        if (horseGender?.length <= 0 ?? false) {
            toast.error(translate('web.genderIsRequired', 'Gender Is Required'))
            return;
        }
        if (horseColor?.length <= 0 ?? false) {
            toast.error(translate('web.colorIsRequired', 'Color Is Required'))
            return;
        }
        if (origin?.length <= 0 ?? false) {
            toast.error(translate('web.originIsRequired', 'Origin Is Required'))
            return;
        }
        if (horseCountry?.length <= 0 ?? false) {
            toast.error(translate('web.horseCountryIsRequired', 'Horse Country Is Required'))
            return;
        }
        if (qualificationCountry?.length <= 0 ?? false) {
            toast.error(translate('web.horseQualificationCountryIsRequired', 'Horse Qualification Country Is Required'))
            return;
        }

        const formData = new FormData();
        for (const key in horseData) {
            if (horseData.hasOwnProperty(key)) {
                formData.append(key, horseData[key])
            }
        }
        formData.append('gender', horseGender.value);
        formData.append('horseColorId', horseColor.value);
        formData.append('originId', origin.value);
        formData.append('currentCountryId', horseCountry.value);
        formData.append('createdBy', userProfile?.id ?? '');
        formData.append('horseQualificationsList[0][countryId]', qualificationCountry.value);
        formData.append('horseQualificationsList[0][showName]', horseData.showName);
        formData.append('horseQualificationsList[0][year]', horseData.showDate);
        formData.append('horseQualificationsList[0][classRank]', horseData.classRank);
        formData.append('horseQualificationsList[0][website]', horseData.referenceWebsiteUrl);
        formData.append('horseQualificationsList[0][className]', horseData.className);
        formData.append('PassPort', passportFiles[0].file);
        formData.append('OwnerProof', ownerShipProf[0].file);
        if (qualificationCertificate.length > 0) {
            formData.append('Certification', qualificationCertificate[0].file);
        }
        if (isEdit) {
            formData.append('id', horseId);
        }

        try {
            setIsAdding(true);
            const res = await axiosBackend.post('/api/ShowEntry/AddEditInternationalHorse', formData, {
                headers: {
                    'content-type': 'multipart/form-data',
                }
            });
            if (res.data.responseCode == "200") {
                toast.success(translate('web.horseAddedSuccessfully', 'Horse Added Successfully'));
                if (isEdit) {
                    createShowEntry(horseId);
                } else {
                    createShowEntry(res.data.result.id);
                }

            } else {
                toast.error(translate('web.errorInHorseAdding', 'Error In Horse Adding Please Contact The Admin'));
                setIsAdding(false);

            }
        } catch (error) {
            toast.error(translate('web.errorInHorseAdding', 'Error In Horse Adding Please Contact The Admin'));
            setIsAdding(false);


        }

    }

    async function createShowEntry(horseID) {
        try {
            const res = await axiosBackend.post('/api/ShowEntry/CreateShowEntry', {
                showId: showID,
                createdBy: userProfile.id,
                ownerId: userProfile.id,
                isDraft: true,
                IsAddToDraft: true,
                showParticipantsHorsesList: [{
                    horseId: horseID,
                    partictedOwnerId: userProfile.id,
                    isRequiredConfirmation: true
                }]
            })
            if (res.status == 200) {
                if (res.data.responseCode == "200") {
                    toast.warn(translate('web.horseIsAddedToDraft', 'Horse Is Added To Draft'));
                    navigate(`/showEntry?id=${showID}`);
                    setIsAdding(false);

                }
            } else {
                toast.error(translate('web.failedToSetHorseAsDraft', 'Failed To Set Horse AsDraft'));
                setIsAdding(false);


            }
        } catch (error) {
            toast.error(translate('web.failedToSetHorseAsDraft', 'Failed To Set Horse AsDraft'));
            console.error('Failed To Set Horse AsDraft', error);

        }

    }

    // get Lists Data
    async function getCountries() {
        const response = await axiosBackend.get('/api/Lookup/GetCountries');
        const formattedData = response?.data?.result.map((x) => ({
            text: currentLanguage == 'en' ? x.name : x.arName,
            value: x.id,
            label: currentLanguage == 'en' ? x.name : x.arName,
            ...x
        }))
        setCountries(formattedData);
    }
    async function getGenders() {
        const response = await axiosBackend.get('/api/Lookup/GetHorseGender');
        const formattedGenders = response?.data?.result.map((x) => ({
            text: x.text,
            value: x.value,
            label: x.text,
            ...x
        }))
        setGenders(formattedGenders);
    }
    async function getColors() {
        const response = await axiosBackend.get('/api/Lookup/GetHorseColors');
        const formattedData = response?.data?.result.map((x) => ({
            text: currentLanguage == 'en' ? x.nameEn : x.nameAr,
            value: x.id,
            label: currentLanguage == 'en' ? x.nameEn : x.nameAr,
            ...x
        }))
        setColors(formattedData);
    }

    async function getHorseData() {
        try {
            const res = await axiosBackend.get(`/api/ShowEntry/GetInternationalHorsesById?id=${horseId}`);
            if (res.status == 200) {
                if (res.data.responseCode == "200") {
                    const data = res.data.result;
                    const { horseQualificationsList,
                        horseDocumentsList,
                        originId,
                        uelnNo,
                        gender,
                        currentCountryId,
                        horseColorId,
                        breederInfo,
                        isOwner,
                        uelnCountry,
                        uelnHorse,
                        uelnNumFull,
                        uelnOrganization,
                        horseUELN,
                        ownerNameAr,
                        ...restofData } = data;
                    const showName = horseQualificationsList.length > 0 ? horseQualificationsList[0].showName : '';
                    const showDate = horseQualificationsList.length > 0 ? horseQualificationsList[0].year : '';
                    const className = horseQualificationsList.length > 0 ? horseQualificationsList[0].className : '';
                    const classRank = horseQualificationsList.length > 0 ? horseQualificationsList[0].classRank : '';
                    const website = horseQualificationsList.length > 0 ? horseQualificationsList[0].website : '';
                    const qualicifationCountry = horseQualificationsList.length > 0 ? horseQualificationsList[0].countryId : '';
                    setHorseData({
                        ...restofData,
                        horseUELN: horseUELN,
                        showName: showName,
                        showDate: showDate,
                        className: className,
                        classRank: classRank,
                        referenceWebsiteUrl: website,
                    });
                    if (!!originId) {
                        const origin = countries.filter((item) => item.value == originId)[0];
                        setOrigin(origin)
                    }
                    if (!!currentCountryId) {
                        const country = countries.filter((item) => item.value == currentCountryId)[0];
                        setHorseCountry(country)
                    }
                    if (!!qualicifationCountry) {
                        const country = countries.filter((item) => item.value == qualicifationCountry)[0];
                        setQualificationCountry(country)
                    }
                    if (!!horseColorId) {
                        const color = colors.filter((item) => item.value == horseColorId)[0];
                        setHorseColor(color);
                    }
                    if (!!gender) {
                        const selectedGender = genders.filter((item) => item.value == gender)[0];
                        setHorseGender(selectedGender);
                    }
                    if (horseDocumentsList.length > 0) {
                        const hasPassport = horseDocumentsList.filter((item) => item.documentType == "Passport").length > 0;
                        if (hasPassport) {
                            const passPortURL = horseDocumentsList.filter((item) => item.documentType == "Passport")[0];
                            setPassportFiles([{
                                documentFile: passPortURL.documentUrl,
                                name: passPortURL.documentType,
                                file: passPortURL.documentUrl,
                            }])
                        }
                        const hasOwnerProf = horseDocumentsList.filter((item) => item.documentType == "OwnerProof").length > 0;
                        if (hasOwnerProf) {
                            const hasOwnerProfURL = horseDocumentsList.filter((item) => item.documentType == "OwnerProof")[0];
                            setOwnerShipProf([{
                                documentFile: hasOwnerProfURL.documentUrl,
                                name: hasOwnerProfURL.documentType,
                                file: hasOwnerProfURL.documentUrl,
                            }])
                        }
                        const hasCertificate = horseDocumentsList.filter((item) => item.documentType == "Certification").length > 0;
                        if (hasCertificate) {
                            const hasCertificateURL = horseDocumentsList.filter((item) => item.documentType == "Certification")[0];
                            setQualificationCertificate([{
                                documentFile: hasCertificateURL.documentUrl,
                                name: hasCertificateURL.documentType,
                                file: hasCertificateURL.documentUrl,
                            }])
                        }
                    }
                }
            }
        } catch (error) {

        }

    }

    useEffect(() => {

        getColors();
        getGenders();
        getCountries();

    }, []);
    useEffect(() => {
        if (isEdit && countries.length > 0) {
            getHorseData();
        }
    }, [countries])


    return (
        <form key={'addNewHorse'} onSubmit={(e) => handleSubmit(e)}>
            <h4 className='mainColor font-weight-bold' >{translate('web.horseDetails', 'Horse Details')}</h4>
            <div className='row mx-0 my-2 pt-1 pb-3 borderGray border-raduis-15-px'>
                <div className='col-lg-6 col-md-6 col-sm-12 mt-2' >
                    <NetaqInput
                        type={'text'}
                        label={translate('web.horseNameEn', 'Horse Name En')}
                        name={'nameEn'}
                        placeholder={translate('web.horseNameEn', 'Horse Name En')}
                        required={true}
                        value={horseData?.nameEn}
                        onChange={handleChange}
                    />
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12 mt-2' >
                    <NetaqInput
                        type={'text'}
                        label={translate('web.horseNameAr', 'Horse Name Ar')}
                        name={'nameAr'}
                        placeholder={translate('web.horseNameAr', 'Horse Name Ar')}
                        required={true}
                        value={horseData?.nameAr}
                        onChange={handleChange}
                    />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                    <NetaqInput
                        type={"select"}
                        label={translate("web.origin", "Origin")}
                        placeholder={translate("web.origin", "Origin")}
                        name={"origin"}
                        listSelect={countries}
                        value={origin}
                        onChange={(selectedItem) => setOrigin(selectedItem)}
                        required={true}

                    />
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12 mt-2' >
                    <NetaqInput
                        type={'number'}
                        label={translate('web.currentRegNumber', 'Current Reg. Number')}
                        name={'registrationNumber'}
                        placeholder={translate('web.currentRegNumber', 'Current Reg. Number')}
                        required={true}
                        value={horseData?.registrationNumber}
                        onChange={handleChange}
                    />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                    <NetaqInput
                        type={"select"}
                        label={translate("web.color", "Color")}
                        placeholder={translate("web.color", "Color")}
                        name={"color"}
                        listSelect={colors}
                        value={horseColor}
                        onChange={(item) => setHorseColor(item)}
                        required={true}

                    />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                    <NetaqInput
                        type={"select"}
                        label={translate("web.gender", "Gender")}
                        placeholder={translate("web.gender", "Gender")}
                        name={"gender"}
                        listSelect={genders}
                        value={horseGender}
                        onChange={(item) => setHorseGender(item)}
                        required={true}
                    />
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12 mt-2' >
                    <NetaqInput
                        type={'normalDate'}
                        label={translate('web.birthdate', 'birthDate')}
                        name={'birthDate'}
                        value={horseData?.birthDate}
                        placeholder={'************'}
                        required={true}
                        onChange={handleChange}
                    />
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12 mt-2' >
                    <NetaqInput
                        type={'text'}
                        label={translate('web.horseUELN', 'Horse UELN')}
                        name={'horseUELN'}
                        placeholder={'123456789123456'}
                        required={false}
                        value={horseData?.horseUELN}
                        onChange={handleChange}
                    />
                </div>
            </div>
            <h4 className='mainColor font-weight-bold' >{translate('web.ownerDetails', 'Owner Details')}</h4>
            <div className='row mx-0 my-2 pt-1 pb-3 borderGray border-raduis-15-px'>
                <div className='col-12 mt-2'>
                    <NetaqInput
                        name={"isOwnedByMe"}
                        type={"checkbox"}
                        label={translate("web.ownedByMe", "Owned By Me")}
                        defaultChecked={horseData.isOwnedByMe}
                        checked={horseData.isOwnedByMe}
                        onChange={handleChange}
                    />
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12 mt-2' >
                    <NetaqInput
                        type={'text'}
                        label={translate('web.ownerNameEn', 'Owner Name En')}
                        name={'ownerNameEn'}
                        placeholder={translate('web.ownerNameEn', 'owner Name En')}
                        required={true}
                        value={horseData?.breederNameEn}
                        onChange={handleChange}
                        disabled={horseData.isOwnedByMe}
                    />
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12 mt-2' >
                    <NetaqInput
                        type={'text'}
                        label={translate('web.ownerNameAr', 'Owner Name Ar')}
                        name={'ownerNameAr'}
                        placeholder={translate('web.ownerNameAr', 'Owner Name Ar')}
                        required={true}
                        value={horseData?.breederNameEn}
                        onChange={handleChange}
                        disabled={horseData.isOwnedByMe}
                    />
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12 mt-2' >
                    <NetaqInput
                        type={'text'}
                        label={translate('web.breaderNameEn', 'Breader Name En')}
                        name={'breederNameEn'}
                        placeholder={translate('web.breaderName', 'Breader Name')}
                        required={true}
                        value={horseData?.breederNameEn}
                        onChange={handleChange}
                    />
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12 mt-2' >
                    <NetaqInput
                        type={'text'}
                        label={translate('web.breaderNameAr', 'Breader Name Ar')}
                        name={'breederNameAr'}
                        placeholder={translate('web.breaderNameAr', 'Breader Name Ar')}
                        required={true}
                        value={horseData?.breederNameAr}
                        onChange={handleChange}
                    />
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12 mt-2' >
                    <NetaqInput
                        type={'text'}
                        label={translate('web.sireNameEn', 'Sire Name En')}
                        name={'sireNameEn'}
                        placeholder={translate('web.sireName', 'Sire Name')}
                        required={true}
                        value={horseData?.sireNameEn}
                        onChange={handleChange}
                    />
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12 mt-2' >
                    <NetaqInput
                        type={'text'}
                        label={translate('web.sireNameAr', 'Sire Name Ar')}
                        name={'sireNameAr'}
                        placeholder={translate('web.sireNameAr', 'Sire Name Ar')}
                        required={true}
                        value={horseData?.sireNameAr}
                        onChange={handleChange}
                    />
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12 mt-2' >
                    <NetaqInput
                        type={'text'}
                        label={translate('web.damNameEn', 'Dam Name En')}
                        name={'damNameEn'}
                        placeholder={translate('web.damName', 'Dam Name')}
                        value={horseData?.damNameEn}
                        required={true}
                        onChange={handleChange}
                    />
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12 mt-2' >
                    <NetaqInput
                        type={'text'}
                        label={translate('web.damNameAr', 'Dam Name Ar')}
                        name={'damNameAr'}
                        placeholder={translate('web.damNameAr', 'Dam Name Ar')}
                        required={true}
                        value={horseData?.damNameAr}
                        onChange={handleChange}
                    />
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12 mt-2' >
                    <NetaqInput
                        type={"select"}
                        label={translate("web.horseCountry", "Horsse Current Country")}
                        placeholder={translate("web.horseCountry", "Horsse Current Country")}
                        name={"horsseCountry"}
                        listSelect={countries}
                        value={horseCountry}
                        onChange={(item) => setHorseCountry(item)}
                        required={true}
                    />
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12 mt-2' >
                    <NetaqInput
                        type={'text'}
                        label={translate('web.address', 'Address')}
                        name={'address'}
                        placeholder={translate('web.address', 'Address')}
                        required={false}
                        value={horseData?.address}
                        onChange={handleChange}
                    />
                </div>
            </div>
            <h4 className='mainColor font-weight-bold' >{translate('web.horseQualifications', 'Horse Qualifications')}</h4>
            <div className='row mx-0 my-2 pt-1 pb-3 borderGray border-raduis-15-px'>
                <div className='col-12 mt-2'>
                    <NetaqInput
                        name={"isNoQualifications"}
                        type={"checkbox"}
                        label={translate("web.noQualifications", "No Qualifications")}
                        defaultChecked={horseData?.isNoQualifications}
                        checked={horseData?.isNoQualifications}
                        onChange={handleChange}
                    />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                    <NetaqInput
                        type={"select"}
                        label={translate("web.countryName", "Choose Country")}
                        placeholder={translate("web.countryName", "Choose Country")}
                        name={"countryName"}
                        listSelect={countries}
                        value={qualificationCountry}
                        onChange={(item) => setQualificationCountry(item)}
                        required={true}
                        disabled={horseData?.isNoQualifications ?? false}
                    />
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12 mt-2' >
                    <NetaqInput
                        type={'text'}
                        label={translate('web.showName', 'Show Name')}
                        name={'showName'}
                        placeholder={translate('web.showName', 'Show Name')}
                        required={true}
                        value={horseData?.showName}
                        onChange={handleChange}
                        disabled={horseData?.isNoQualifications ?? false}
                    />
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12 mt-2' >
                    <NetaqInput
                        type={'normalDate'}
                        label={translate('web.showDate', 'Show Date')}
                        name={'showDate'}
                        value={horseData?.showDate}
                        placeholder={'************'}
                        required={true}
                        onChange={handleChange}
                        disabled={horseData?.isNoQualifications ?? false}
                    />
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12 mt-2' >
                    <NetaqInput
                        type={'text'}
                        label={translate('web.className', 'Class Name')}
                        name={'className'}
                        placeholder={translate('web.className', 'Class Name')}
                        required={true}
                        value={horseData?.className}
                        onChange={handleChange}
                        disabled={horseData?.isNoQualifications ?? false}
                    />
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12 mt-2' >
                    <NetaqInput
                        type={'number'}
                        label={translate('web.classRank', 'Class Rank')}
                        name={'classRank'}
                        placeholder={translate('web.classRank', 'Class Rank')}
                        required={true}
                        value={horseData?.classRank}
                        onChange={handleChange}
                        disabled={horseData?.isNoQualifications ?? false}
                    />
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12 mt-2' >
                    <NetaqInput
                        type={'text'}
                        label={translate('web.referenceWebsiteUrl', 'Reference Website Url')}
                        name={'referenceWebsiteUrl'}
                        placeholder={translate('web.referenceWebsiteUrl', 'Reference Website Url')}
                        required={true}
                        value={horseData?.referenceWebsiteUrl}
                        onChange={handleChange}
                        disabled={horseData?.isNoQualifications ?? false}
                    />
                </div>
            </div>
            <h4 className='mainColor font-weight-bold' >{translate('web.uploadHorseDocuments', 'Upload Horse Documents')}</h4>
            <div className='row mx-0 my-2 pt-1 pb-3 borderGray border-raduis-15-px'>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                    <SimpleFileUpload accept="image/*,application/pdf" label={translate('web.passport', 'Passport')} required={true} isMulti={false} isServer={isEdit} initialFiles={passportFiles} onChange={(e) => handleFilesUploaded(e, 'passport')} key={'passport'} />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                    <SimpleFileUpload accept="image/*,application/pdf" label={translate('web.ownerShipProf', 'Owner Ship Prof')} required={true} isMulti={false} isServer={isEdit} initialFiles={ownerShipProf} onChange={(e) => handleFilesUploaded(e, 'ownerShipProf')} key={'ownerShipProf'} />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                    <SimpleFileUpload accept="image/*,application/pdf" label={translate('web.qualificationCertificate', 'Qualification Certificate')} required={false} isMulti={false} isServer={false} initialFiles={qualificationCertificate} onChange={(e) => handleFilesUploaded(e, 'qualificationCertificate')} key={'qualificationCertificate'} />
                </div>
            </div>
            <div className='row mx-0 my-2'>
                <div className='col-sm-2 col-6 ms-auto text-center' >
                    {isAdding ?
                        <Loader />
                        :
                        <CustomButton key={'submit'} type='submit' htmlText={`<i class="fa fa-check"></i> Submit`} onClick={(e) => handleSubmit(e)} className='btn-success' />
                    }
                </div>
            </div>
        </form>
    )
}
