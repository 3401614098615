import React, { useState } from 'react'
import NetaqInput from './NetaqInput'
import CustomButton from './CustomButton'
import axiosBackend from '../axios/axios'
import { useTranslate } from './TranslationText'

export default function ContactForm({ toast }) {
    const { translate } = useTranslate();
    const [inquiryData, setInquiryData] = useState({});

    async function sendContactForm(e) {
        e.preventDefault();
        try {
            const sendInquiry = await axiosBackend.post('api/Contact/SubmitEmail', inquiryData);
            if (sendInquiry.status == 200) {
                toast.success(translate('web.yourInquiryIsSentSuccessfully', 'Your Inquiry Is Sent Successfully'));
            } else {
                toast.error(translate('web.errorInSendingMail', 'Error In Sending Mail'));
            }
        } catch (error) {
            toast.error(translate('web.errorInSendingMail', 'Error In Sending Mail'));
        }
    }

    function handleChange(e) {
        setInquiryData((prev) => ({
            ...prev,
            [e.target.name]: e.target.value
        }));
    }

    //   "phone": "string",

    return (
        <form onSubmit={sendContactForm}>
            <NetaqInput name={'name'} label={translate('web.yourName', 'Your name')} onChange={handleChange} type={'text'} required={true} />
            <NetaqInput name={'email'} label={translate('web.yourEmail', 'Your Email')} onChange={handleChange} type={'email'} required={true} />
            <NetaqInput name={'subject'} label={translate('web.yourSubject', 'Your Subject')} onChange={handleChange} type={'text'} required={true} />
            <NetaqInput name={'message'} label={translate('web.yourMessage', 'Your Message')} onChange={handleChange} type={'textarea'} required={true} />
            <CustomButton key={'submit'} type='submit' className='btn-success w-50' htmlText={translate('web.submit', 'SUBMIT')} />
        </form>
    )
}
