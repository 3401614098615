import React, { useRef } from 'react'

export default function ImageSlider({ items = [{ type: '', link: '' }] }) {

    const carouselItems = items.filter((item) => !!item?.link);

    return (
        carouselItems.length > 0 && <div id="carouselExampleIndicators" className="carousel slide">
            <div className="carousel-indicators">
                {carouselItems.map((item, index) => (
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={`${index}`} className={`${index == 0 ? 'active' : ''}`} aria-current="true" aria-label={`Slide ${index}`}></button>
                ))}

            </div>
            <div className="carousel-inner">
                {carouselItems.map((item, index) => (
                    item.type == 'img' ?
                        <div className={`carousel-item ${index == 0 ? 'active' : ''}`}>
                            <img src={item?.link} className="d-block w-100" alt={item?.link} />
                        </div> :
                        <div className={`carousel-item ${index == 0 ? 'active' : ''} `}>
                            <iframe width="100%" height="500" style={{ maxHeight: 460.5 }} src={item?.link} title="YouTube video player" frameborder="0" allow="muted; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen={true}></iframe>
                        </div>
                ))}
            </div>

        </div>
    )
}
