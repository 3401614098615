import React, { useState, useEffect } from "react";
import LoginForm from "../components/LoginForm";
import './../commonCss/loginPage.css'
import OTPForm from "../components/OTPForm";
import axiosBackend from "../axios/axios";
import { useTranslate } from "../components/TranslationText";

const BaseURL = process.env.REACT_APP_BASE_URL;

export default function LoginPage({ toast }) {
    const { translate } = useTranslate();
    const [isWideScreen, setIsWideScreen] = useState(true);
    const [showOTP, setShowOTP] = useState(false);
    const [otp, setOTP] = useState('');
    const [otpSent, setOTPSent] = useState(false);
    const [credForOTP, setCredForOTP] = useState({});

    async function sendOTP(e) {
        e.preventDefault();
        debugger;
        try {
            const res = await axiosBackend.post('/api/Account/complete-register', { otp: otp, email: credForOTP?.email });
            if (res.status == 200) {
                if (res?.data?.status?.responseCode == "200") {
                    toast.success(translate('web.youAccountIsActivatedSuccessfully', 'You Account Is Activated Successfully'));
                    setOTPSent(true)
                } else {
                    toast.error(translate('web.somethingWentWrongPleaseTryAgain', 'Something Went Wrong Please Try Again'));
                }
            } else {
                toast.error(translate('web.somethingWentWrongPleaseTryAgain', 'Something Went Wrong Please Try Again'));
            }
        } catch (error) {
            toast.error(translate('web.somethingWentWrongPleaseTryAgain', 'Something Went Wrong Please Try Again'));
        }
    }

    useEffect(() => {
        const handleResize = () => {
            setIsWideScreen(window.innerWidth >= 600);
        };

        handleResize(); // Initial check
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);


    return (
        <>

            <div className="LoginPage">
                {isWideScreen && <div style={{ flex: 1 }}></div>}
                <div className="LoginFormContainer">
                    <LoginForm toast={toast} setShowOTP={setShowOTP} setCredForOTP={setCredForOTP} otpSent={otpSent} />
                </div>
                {showOTP &&
                    <div className='OTPOverLay'>
                        <div className='LoginFormContainer'>
                            <OTPForm key={'otpForm'} setOTP={setOTP} onSubmit={sendOTP} isRegisterPage={true} setShowOTP={setShowOTP} />
                        </div>
                    </div>
                }
            </div>
        </>
    );
}
