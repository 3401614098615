import React, { useEffect, useState } from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { formatDate } from './../helper/formatDate';
import '../commonCss/classesCss.css'
import { useTranslate } from './TranslationText';
import axiosBackend from '../axios/axios';
import { getOrdinal } from '../helper/getOrdinal';
import { convertToCamelCase } from '../helper/convertToCamelCase';

export default function ClassesSection({ showID }) {
    const { translate } = useTranslate();
    const [classes, setClasses] = useState([]);
    const currentLanguage = localStorage.getItem('ecupLang') ?? 'en';

    async function getClasses() {
        try {
            const res = await axiosBackend.get(`/api/Show/GetShowClasses?id=${showID}`);

            console.log('classes is', res.data);
            setClasses(res?.data?.result ?? []);

        } catch (error) {
            console.error('error in getting Classes')
        }

    }

    useEffect(() => {
        getClasses();
    }, [])


    return (
        <div>
            <h2 className='roboto-custom-black' >{translate('web.classes', 'Classes')}</h2>
            {classes.length > 0 && classes.map((item, index) => (
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel-content"
                        id="panel-header"
                        focusVisibleClassName='bg-success'
                    >
                        {new Date(item?.date).toDateString()}
                    </AccordionSummary>
                    <AccordionDetails>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel-content"
                                id="panel-header"
                            >
                                {item?.name}
                            </AccordionSummary>
                            <AccordionDetails>
                                {item?.results?.length > 0 && item.results.map((horse, index) => (

                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel-content"
                                            id="panel-header"
                                        >
                                            <div className='row w-100' >
                                                <div className='col-sm-3 col-6' dangerouslySetInnerHTML={{ __html: getOrdinal(horse?.position, currentLanguage) }} />
                                                <div className='col-sm-3 col-6' >{horse?.number} – {horse?.horse?.name}</div>
                                                <div className='col-sm-3 col-6' >{horse?.score} {!!horse?.reason && ' - ' + translate('web.' + convertToCamelCase(horse?.reason), horse?.reason)}</div>
                                                <div className='col-sm-3 col-6 ' >{translate('web.results', 'Results')}</div>
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div className='row w-100'>
                                                <div className='col-lg-4 mt-2 col-md-4 col-sm-6 col-12'>
                                                    <h6 className='roboto-light'>
                                                        {translate('web.birthdate', 'Birthdate')}
                                                    </h6>
                                                    <span>{formatDate(horse?.horse?.birthday)}</span>
                                                </div>
                                                <div className='col-lg-4 mt-2 col-md-4 col-sm-6 col-12'>
                                                    <h6 className='roboto-light'>
                                                        {translate('web.sire', 'Sire')}
                                                    </h6>
                                                    <span style={{ textTransform: 'uppercase' }}>{horse?.horse?.sire}</span>
                                                </div>
                                                <div className='col-lg-4 mt-2 col-md-4 col-sm-6 col-12'>
                                                    <h6 className='roboto-light'>
                                                        {translate('web.breeder', 'Breeder')}
                                                    </h6>
                                                    <span style={{ textTransform: 'uppercase' }}>{horse?.horse?.breeder}</span>
                                                </div>
                                                <div className='col-lg-4 mt-2 col-md-4 col-sm-6 col-12'>
                                                    <h6 className='roboto-light'>
                                                        {translate('web.color', 'Color')}
                                                    </h6>
                                                    <span style={{ textTransform: 'uppercase' }}>{horse?.horse?.color}</span>
                                                </div>
                                                <div className='col-lg-4 mt-2 col-md-4 col-sm-6 col-12'>
                                                    <h6 className='roboto-light'>
                                                        {translate('web.dam', 'Dam')}
                                                    </h6>
                                                    <span style={{ textTransform: 'uppercase' }}>{horse?.horse?.dam}</span>
                                                </div>
                                                <div className='col-lg-4 mt-2 col-md-4 col-sm-6 col-12'>
                                                    <h6 className='roboto-light'>
                                                        {translate('web.owner', 'Owner')}
                                                    </h6>
                                                    <span style={{ textTransform: 'uppercase' }}>{horse?.horse?.owner}</span>
                                                </div>

                                                <div className='col-12 mt-2 overflow-auto'>
                                                    <table className='w-100 minWidth600 '>
                                                        <thead>
                                                            <tr className='bgDarkGray text-center'>
                                                                <th className='roboto-light font-weight-bold text-white'></th>
                                                                <th className='roboto-light font-weight-bold text-white'>{translate('web.type', 'Type')}</th>
                                                                <th className='roboto-light font-weight-bold text-white'>{translate('web.headAndNeck', 'H&N')}</th>
                                                                <th className='roboto-light font-weight-bold text-white'>{translate('web.body', 'Body')}</th>
                                                                <th className='roboto-light font-weight-bold text-white'>{translate('web.legs', 'Legs')}</th>
                                                                <th className='roboto-light font-weight-bold text-white'>{translate('web.move', 'Move')}</th>
                                                                <th className='roboto-light font-weight-bold text-white'>{translate('web.total', 'Total')}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {horse?.full_results?.length > 0 ?
                                                                horse?.full_results.map((row, index) => (
                                                                    <tr className='text-center'>
                                                                        <td className='bgDarkGray text-white' >{row?.judge?.annotation}</td>
                                                                        <td>{row?.scoring?.find((item) => item.name == 'Type')?.value}</td>
                                                                        <td>{row?.scoring?.find((item) => item.name == 'Head&Neck')?.value}</td>
                                                                        <td>{row?.scoring?.find((item) => item.name == 'Body')?.value}</td>
                                                                        <td>{row?.scoring?.find((item) => item.name == 'Leg')?.value}</td>
                                                                        <td>{row?.scoring?.find((item) => item.name == 'Movement')?.value}</td>
                                                                        {index == 0 && <td className='bgGray' rowSpan={horse?.full_results?.length}>
                                                                            <div className='d-flex justify-content-center flex-column align-items-center'>
                                                                                <div className='capatalize_text'>{horse?.score}</div>
                                                                                <div className='capatalize_text' >{translate('web.points', 'Points')} </div>
                                                                            </div>
                                                                        </td>}
                                                                    </tr>
                                                                ))
                                                                : <tr className='text-center'>
                                                                    <td colSpan={7} className='text-center text-danger fw-bold' >{translate('web.noResultsFound', 'No Results Found')}</td>
                                                                </tr>
                                                            }
                                                        </tbody>
                                                    </table>
                                                    <sub className='mt-2'>
                                                        {translate('web.judge', 'Judges')}: {horse?.full_results?.map((judgeContainer, index) => (`${judgeContainer?.judge?.annotation} - ${judgeContainer?.judge?.name}${(index + 1 !== horse?.full_results?.length) && ', '}`))}
                                                    </sub>
                                                </div>
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                ))}
                            </AccordionDetails>
                        </Accordion>
                    </AccordionDetails>
                </Accordion>
            ))}
        </div>
    )
}
