import React, { useEffect, useState } from "react";
import NetaqInput from "./NetaqInput";
import { useTranslate } from "./TranslationText";
import CustomButton from "./CustomButton";

export default function OTPForm({ onSubmit, setOTP, sendNewPassword, isRegisterPage = false, setShowOTP }) {
  const { translate } = useTranslate();
  const [password, setPassWord] = useState(null);
  const [newPassword, setNewPassword] = useState(null);
  const [error, setError] = useState(translate('web.passwordIsNotMatch', 'Password Is Not Match'));
  const [passwordMatch, setPasswordMatch] = useState(false);

  useEffect(() => {
    if ((password !== newPassword) && (!!password && !!newPassword)) {
      setPasswordMatch(false)
    } else {
      if ((password === newPassword) && (!!password && !!newPassword)) {
        setPasswordMatch(true);
        sendNewPassword(newPassword);
      } else {
        setPasswordMatch(false);
      }
    }
  }, [password, newPassword])

  const handleChange = (event, inputName) => {
    if (inputName == 'p1') {
      setPassWord(event.target.value)
      return;
    }
    if (inputName == 'p2') {
      setNewPassword(event.target.value)
    }

  }

  return (
    <form className="rtl borderGray" onSubmit={onSubmit}
      style={{
        backgroundColor: "#F7F7F7",
        padding: '5px 25px',
        display: "flex",
        flexDirection: "column",
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        width: '100%',
        margin: "auto",
        minheight: 250,
        minWidth: 301,
        zIndex: 1000

      }}
    >
        <i className="fa fa-times-circle fa-lg text-danger " style={{cursor:'pointer' , position:'absolute' , top:-1 , left:-4}} onClick={()=>setShowOTP(false)}></i>
      {isRegisterPage ?
        <NetaqInput
          name={"token"}
          label={translate('web.otp', 'OTP')}
          key={"OTP"}
          placeholder={"XXXXXX"}
          type={"text"}
          onChange={(event) => setOTP(event.target.value)}
        /> : <>
          <NetaqInput
            name={"token"}
            label={translate('web.resetPasswordToken', 'Reset Password Token')}
            key={"OTP"}
            placeholder={"XXXXXX"}
            type={"text"}
            onChange={(event) => setOTP(event.target.value)}
          />
          <NetaqInput
            name={"password"}
            label={translate('web.newPassword', 'New Password')}
            key={"password"}
            placeholder={"*******"}
            type={"password"}
            onChange={(evt) => handleChange(evt, 'p1')}
          />
          <NetaqInput
            name={"newPassword"}
            label={translate('web.confirmNewPassword', 'Confirm New Password')}
            key={"newPassword"}
            placeholder={"*******"}
            type={"password"}
            onChange={(evt) => handleChange(evt, 'p2')}
          />

          {(password !== newPassword) && (!!password && !!newPassword) &&
            <div className="text-danger">
              {error}
            </div>
          }
        </>}

      <div
        className="mt-2"
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        {isRegisterPage ?
          <CustomButton
            className="btn-danger"
            key={"loginuaepass"}
            htmlText={translate("web.submit", "Submit")}
            type={"submit"}
          /> :
          <CustomButton
            className="btn-danger mt-2"
            disabled={!passwordMatch}
            key={"loginuaepass"}
            htmlText={translate("web.submit", "Submit")}
            type={"submit"}
          />
        }
      </div>
    </form>
  );
}
