import React, { useEffect, useRef, useState } from "react";
import NetaqInput from "./NetaqInput";
import Loader from "./Loader";
import { useTranslate } from "./TranslationText";
import CustomButton from "./CustomButton";
import OTPForm from "./OTPForm";
import axiosBackend from "../axios/axios";
import { useNavigate } from "react-router";

export default function ForgetPasswordForm({ toast }) {
  const [email, setEmail] = useState('');
  const [loader, setLoader] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [otp, setOTP] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const navigate = useNavigate();

  const { translate } = useTranslate();




  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!!!email) {
      toast.error(translate('web.emailIsRequired', 'Email Is Required'));
      return;
    }

    setLoader(true);


    try {
      const res = await axiosBackend.post('/api/Account/forgotpassword', { email: email });

      if (res.status == 200) {
        setLoader(false);
        setShowOTP(true);
        toast.success(translate('web.otpSentToYourEmail', 'Otp Sent To Your Email'));
      } else {
        setLoader(false);
        toast.error(translate('web.failedToSendOTP', 'Failed To Send OTP'));
      }

    } catch (error) {
      console.error('error in sending OTP', error);
      setLoader(false);
      toast.error(translate('web.failedToSendOTP', 'Failed To Send OTP'));

    }
  }


  const otpSubmit = async () => {
    if (!!!otp) {
      toast.error(translate('web.otpTokenIsRequired', 'OTP Token Is Required'));
      return;
    }
    if (!!!newPassword) {
      toast.error(translate('web.newPasswordIsRequired', 'New Password Is Required'));
      return;
    }

    try {
      const response = await axiosBackend.post('/api/Account/resetpassword', {
        email: email,
        token: otp,
        newPassword: newPassword
      })
      if (response.status == 200) {
        toast.success(translate('web.passwordResetSuccssfully', 'Password Reset Successfully'));
        navigate('/login');
      } else {
        toast.error(translate('web.errorInResettingPassword', 'Error In Resetting Password'));

      }
    } catch (error) {
      toast.error(translate('web.errorInResettingPassword', 'Error In Resetting Password'));
      console.error('Error in Reset Password', error);
    }


  }


  return (
    <>
      <form className="bg-gray-p-25bg-gray-p-25"
        onSubmit={handleSubmit}
      >
        <h2>{translate('web.forgetPassword', 'Forget Password')}</h2>
        <h6>
          {translate('web.pleaseEnterYourEmailtoSendPasswordResetLink', 'Please Enter Your Email to Send Password Reset Link')}
        </h6>
        <NetaqInput
          name={"email"}
          key={"email"}
          required={true}
          label={translate('web.email', 'email')}
          placeholder={"Email"}
          type={"email"}
          onChange={(e) => setEmail(e.target.value)}
        />



        <div className="text-center mt-3">
          {!!loader ? <Loader /> :
            <CustomButton
              key={"apply"}
              className="btn-success "
              htmlText={translate('web.sendResetLink', "Send Reset Link")}
              type={"submit"}
            />
          }
        </div>
      </form>
      {showOTP && <OTPForm onSubmit={otpSubmit} isRegisterPage={false} key={'otpForm'} sendNewPassword={setNewPassword} setOTP={setOTP} />}

    </>

  );
}
