import axiosBackend from "../../axios/axios";

export const fetchTranslations = () => async (dispatch) => {
    dispatch({ type: 'FETCH_TRANSLATIONS_REQUEST' });
    const currentLang = localStorage.getItem('ecupLang') ?? 'en';
  
    try {
        const response = await axiosBackend.get(`/api/Lookup/GetLanguage?language=${currentLang == 'en'?'1':'2'}`)
        const data = response.data.result;
  
      dispatch({ type: 'FETCH_TRANSLATIONS_SUCCESS', payload: data });
    } catch (error) {
      dispatch({ type: 'FETCH_TRANSLATIONS_FAILURE', payload: error.message });
    }
  };
  