import React, { useEffect, useState } from 'react'
import NetaqInput from './NetaqInput'
import { useTranslate } from './TranslationText'
import SimpleFileUpload from './SimpleFileUpload';
import CustomButton from './CustomButton';
import Loader from './Loader';
import axiosBackend from '../axios/axios';



export default function RegisterForm({ toast, setShowOTP, setEmail, setPassword }) {
    const { translate } = useTranslate();
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [registrationObject, setRegistrationObject] = useState({ gender: 1 });
    const [countries, setCountries] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    const [currency, setCurreny] = useState([]);
    const [country, setCountry] = useState([]);
    const [bankCountry, setBankCountry] = useState([]);
    const currentLanguage = localStorage.getItem('ecupLang') ?? 'en';



    // upload ID File
    const handleFilesUploaded = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            let checkFileSize = true;
            const fileListHook = [];

            for (let fileIndex = 0; fileIndex < e.target.files.length; fileIndex++) {
                if (e.target.files[fileIndex].size > 10 * 1024 * 1024) {
                    checkFileSize = false;
                    toast.error(translate("web.fileSizeLimit", "File size 10MB limit."));
                } else {
                    // Check allowed file types
                    const file = e.target.files[fileIndex];
                    const allowedMimeTypes = ['image/', 'application/pdf'];
                    if (!allowedMimeTypes.some(type => file.type.startsWith(type))) {
                        toast.error(translate("web.invalidFileType", "Only images and PDFs allowed."));
                        continue; // Skip to the next file if type is not allowed
                    }
                    fileListHook.push({
                        documentFile: URL.createObjectURL(e.target.files[fileIndex]),
                        name: e.target.files[fileIndex].name,
                        file: e.target.files[fileIndex],
                    });
                }
            }

            setUploadedFiles(fileListHook);
        }
    };

    const handleChange = (e) => {
        if (e.target.name == 'email') {
            setEmail(e.target.value);
        }
        if (e.target.name == 'password') {
            setPassword(e.target.value);
        }
        setRegistrationObject((prev) => ({
            ...prev,
            [e.target.name]: e.target.value
        }));
    }

    const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);
    const setPhoneNumber = (value) => {
        setRegistrationObject((prev) => ({
            ...prev,
            'phone': value
        }));
    }



    const submitForm = async (e) => {
        e.preventDefault();
        if (!!!registrationObject?.fullName ?? true) {
            toast.error(translate('web.fullNameIsRequired', 'Full Name Is Required'));
            return;
        }
        if (!!!registrationObject?.fullNameAr ?? true) {
            toast.error(translate('web.fullNameArIsRequired', 'Full Name Arabic Is Required'));
            return;
        }
        if (registrationObject?.password != registrationObject?.confirmPassword ?? true) {
            toast.error(translate('web.passwordsAreNotMatched', 'Passwords Are Not Matched'));
            return;
        }
        if (!!!registrationObject?.password ?? true) {
            toast.error(translate('web.passwordIsRequired', 'Password is Required'));
            return;
        }
        if (!!!registrationObject?.confirmPassword ?? true) {
            toast.error(translate('web.confirmPasswordIsRequired', 'Confirm Password is Required'));
            return;
        }
        if (!!!registrationObject?.phone ?? true) {
            toast.error(translate('web.phoneIsRequired', 'Phone is Required'));
            return;
        }
        if (!!!registrationObject?.birthDate ?? true) {
            toast.error(translate('web.birthDateIsRequired', 'BirthDate is Required'));
            return;
        }
        if (!!!registrationObject?.address ?? true) {
            toast.error(translate('web.addressIsRequired', 'Address is Required'));
            return;
        }
        if (!!!registrationObject?.bankAccount ?? true) {
            toast.error(translate('web.bankAccountIsRequired', 'Bank Account is Required'));
            return;
        }
        if (!!!registrationObject?.idNumber ?? true) {
            toast.error(translate('web.idNumberIsRequired', 'Id Number is Required'));
            return;
        }
        if (country.length == 0) {
            toast.error(translate('web.countryIsRequired', 'Country is Required'));
            return;
        }
        if (currency.length == 0) {
            toast.error(translate('web.currencyIsRequired', 'Currency is Required'));
            return;
        }
        if (uploadedFiles.length == 0) {
            toast.error(translate('web.passportCopyIsRequired', 'Passport Copy is Required'));
            return;
        }
        const fromData = new FormData();
        // loop on object
        for (const key in registrationObject) {
            if (registrationObject.hasOwnProperty(key)) {
                fromData.append(key, registrationObject[key]);
            }
        }
        fromData.append('currency', currency.value);
        fromData.append('nationalityId', country.value);
        fromData.append('File', uploadedFiles[0].file);
        fromData.append('idType', 1);
        fromData.append('accountType', 1);
        fromData.append('accountCategory', 1);

        try {
            setIsSubmitting(true);
            const res = await axiosBackend.post('/api/Account/register', fromData, {
                headers: {
                    'content-type': 'multipart/form-data',
                }
            });
            if (res.data.status.responseCode == "200") {
                toast.success(translate('web.registeredSuccesfully', 'Registered Successfully'));
                setIsSubmitting(false);
                setShowOTP(true);
            } else {
                toast.error(translate('web.errorInRegistrationPleaseContactTheAdmin', 'Error In Registration Please Contact The Admin'));
                setIsSubmitting(false);
            }

        } catch (error) {
            toast.error(translate('web.errorInRegistrationPleaseContactTheAdmin', 'Error In Registration Please Contact The Admin'));
            setIsSubmitting(false);
        }

    }


    // get Lists Data
    async function getCountries() {
        const response = await axiosBackend.get('/api/Lookup/GetCountries');
        const formattedData = response?.data?.result.map((x) => ({
            text: currentLanguage == 'en' ? x.name : x.arName,
            value: x.id,
            ...x
        }))
        setCountries(formattedData);
    }
    async function GetCurrencies() {
        const response = await axiosBackend.get('/api/Lookup/GetCurrencies');
        const formattedData = response?.data?.result.map((x) => ({
            text: x.text,
            value: x.value,
            ...x
        }))
        setCurrencies(formattedData);
    }


    useEffect(() => {
        GetCurrencies();
        getCountries();
    }, [])

    return (
        <>

            <form className='' onSubmit={submitForm} >
                <div className='row mx-0 my-2 py-3 borderGray border-raduis-15-px'>
                    <h4 className='mainColor font-weight-bold' >{translate('web.basicInfo', 'Basic Information')}</h4>
                    <div className='col-lg-6 col-md-6 col-sm-12 mt-2' >
                        <NetaqInput
                            type={'text'}
                            label={translate('web.fullName', 'Full Name')}
                            name={'fullName'}
                            placeholder={'Mohammad Ahmad'}
                            required={true}
                            onChange={handleChange}
                        />
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12 mt-2' >
                        <NetaqInput
                            type={'text'}
                            label={translate('web.fullNameAr', 'Full Name Arabic')}
                            name={'fullNameAr'}
                            placeholder={'محمد أحمد'}
                            required={true}
                            onChange={handleChange}
                        />
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12 mt-2' >
                        <NetaqInput
                            type={'email'}
                            label={translate('web.email', 'Email')}
                            name={'email'}
                            placeholder={'mail@mail.com'}
                            required={true}
                            onChange={handleChange}
                        />
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12 mt-2' >
                        <NetaqInput
                            type={'phone'}
                            label={translate('web.mobileNumber', 'Mobile Number')}
                            name={'phone'}
                            placeholder={'971xxxxxxxxx'}
                            required={true}
                            onChange={setPhoneNumber}
                            setIsPhoneNumberValid={setIsPhoneNumberValid}
                        />
                        {!isPhoneNumberValid &&
                            <div className='text-danger'>
                                {translate('web.phoneNumberIsNotValid', 'Phone Number Is Not Valid')}
                            </div>
                        }
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12 mt-2' >
                        <NetaqInput
                            type={'normalDate'}
                            label={translate('web.birthDate', 'birthDate')}
                            name={'birthDate'}
                            placeholder={'************'}
                            required={true}
                            value={registrationObject.birthDate}
                            onChange={handleChange}
                        />
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12 mt-2' >
                        <label className="capatalize_text">
                            {translate('web.gender', 'Gender')} :
                        </label>
                        <div className="row mx-0 rtl">
                            <div className="col-lg-6 col-md-6 col-sm-6 align-content-center">
                                <NetaqInput
                                    type={"radio"}
                                    label={translate('web.male', 'Male')}
                                    name={"gender"}
                                    value={1}
                                    checked={true}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 align-content-center" >
                                <NetaqInput
                                    type={"radio"}
                                    label={translate('web.female', 'Female')}
                                    name={"gender"}
                                    value={2}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                        {countries.length > 0 &&
                            <NetaqInput
                                key={'countries'}
                                type={"select"}
                                label={translate("web.nationality", "Nationality")}
                                placeholder={translate("web.nationality", "Nationality")}
                                name={"nationalityId"}
                                listSelect={countries}
                                value={country}
                                onChange={(item) => setCountry(item)}
                                required={true}
                            />
                        }
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                        <NetaqInput
                            type={"text"}
                            label={translate("web.address", "Address")}
                            placeholder={translate("web.address", "Address")}
                            name={"address"}
                            key={"address"}
                            onChange={handleChange}
                            required={true}
                        />
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12 mt-2' >
                        <NetaqInput
                            type={'password'}
                            label={translate('web.password', 'Password')}
                            name={'password'}
                            placeholder={'************'}
                            required={true}
                            onChange={handleChange}
                        />
                        {((registrationObject?.password != registrationObject?.confirmPassword) ?? false) &&
                            <span className='text-danger' > {translate('web.passwordsAreNotMatch', 'Passwords Are Not Match')} </span>
                        }
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12 mt-2' >
                        <NetaqInput
                            type={'password'}
                            label={translate('web.confirmPassword', 'Confirm Password')}
                            name={'confirmPassword'}
                            placeholder={'************'}
                            required={true}
                            onChange={handleChange}
                        />
                    </div>
                    <h4 className='mainColor font-weight-bold mt-3' >{translate('web.bankAccountDetails', 'Bank Account Details')}</h4>
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                        <NetaqInput
                            type={"text"}
                            label={translate("web.bankName", "Bank Name")}
                            placeholder={translate("web.bankName", "Bank Name")}
                            name={"bankName"}
                            key={"Bank Name"}
                            onChange={handleChange}
                            required={true}
                        />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                        <NetaqInput
                            type={"text"}
                            label={translate("web.iban", "IBAN")}
                            placeholder={translate("web.iban", "Iban")}
                            name={"bankAccount"}
                            key={"IBAN"}
                            onChange={handleChange}
                            required={true}
                        />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                        <NetaqInput
                            key={'Currency'}
                            type={"select"}
                            label={translate("web.currency", " Currency")}
                            placeholder={translate("web.currency", " Currency")}
                            name={"currency"}
                            listSelect={currencies}
                            value={currency}
                            onChange={(item) => setCurreny(item)}
                            required={true}

                        />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                        {countries.length > 0 &&
                            <NetaqInput
                                key={'bankCountry'}
                                type={"select"}
                                label={translate("web.bankCountry", "Bank Country")}
                                placeholder={translate("web.bankCountry", "Bank Country")}
                                name={"bankCountry"}
                                listSelect={countries}
                                value={bankCountry}
                                onChange={(item) => setBankCountry(item)}
                                required={true}
                            />
                        }
                    </div>
                    <h4 className='mainColor font-weight-bold mt-3' >{translate('web.idDetails', 'ID Details')}</h4>
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                        <NetaqInput
                            key={'IDTYPE'}
                            type={"text"}
                            label={translate("web.passportNumber", "Passport Number")}
                            placeholder={'123456789123456'}
                            name={"idNumber"}
                            onChange={handleChange}
                            required={true}
                        />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                        <SimpleFileUpload accept="image/*,application/pdf" label={translate('web.passportCopy', 'Passport Copy')} required={true} isMulti={false} isServer={false} initialFiles={uploadedFiles} onChange={handleFilesUploaded} key={'ID UPLOAD'} />
                    </div>
                </div>
                <div className='row mx-0 my-3'>
                    <div className='col-12 ms-1 text-start '>
                        <div className='ms-auto' style={{ maxWidth: 250 }}>
                            {isSubmitting ?
                                <Loader /> :
                                <CustomButton key={'submit'} type="submit" onClick={submitForm} className="btn-success" htmlText={translate('web.signUp', 'SignUp')} />
                            }
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}
