import React, { useEffect, useState } from 'react'
import { useTranslate } from './TranslationText';
import CategoryCard from './CategoryCard';
import axiosBackend from '../axios/axios';

export default function CategoriesSection({ showId }) {
    const { translate } = useTranslate();
    const [categoriesData, setCategoriesData] = useState([]);
    async function getCategories() {
        try {
            const res = await axiosBackend(`/api/ShowEntry/GetShowEntryCategories?id=${showId}`);
            if (res.status == 200) {
                if (res.data.responseCode == "200") {
                    setCategoriesData(res.data.result);
                }
            }
        } catch (error) {
            console.error('error in fetching categories');
        }
    }
    useEffect(() => {
        getCategories();
    }, [])

    return (
        categoriesData.length > 0 &&
        <>
            <h2 className='roboto-custom-black' >{translate('web.categories', 'Categories')}</h2>
            <div className='row mx-0' >
                {categoriesData.map((item, index) => (
                    <CategoryCard key={`catCard${index}`} item={item} translate={translate} />
                ))}
            </div>
        </>
    )
}
