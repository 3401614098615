import React, { useEffect, useRef, useState } from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import '../commonCss/calender.css'
import axiosUmbraco from '../axios/axiosUmbraco';
import { formatDate } from '../helper/formatDate';
import { format, addDays, isBefore } from 'date-fns';

const BaseURL = process.env.REACT_APP_BACKEND_API;
const pageId = process.env.REACT_APP_SEASONS_ID;

export default function CalanderPage() {
    const currentLanguage = localStorage.getItem('ecupLang') ?? 'en';
    const [Today, setToday] = useState(new Date());
    const [calenderEvents, setCalenderEvents] = useState([]);
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const [realEvents, setRealEvents] = useState([]);
    const calanderRef = useRef(null);

    //make events 
    function duplicateEventsBasedOnDates(events) {
        console.debug('events before', events);
        const newList = [];

        events.forEach((event) => {
            const startDate = new Date(event.start);
            const endDate = new Date(event.end);

            let currentDate = startDate;
            while (isBefore(currentDate, addDays(endDate, 1))) {
                newList.push({
                    ...event,
                    startDate: format(currentDate, 'yyyy-MM-dd'),
                    start: format(currentDate, 'yyyy-MM-dd'),
                    date: format(currentDate, 'yyyy-MM-dd'),
                    end: format(currentDate, 'yyyy-MM-dd'),
                    endDate: format(currentDate, 'yyyy-MM-dd')
                });
                currentDate = addDays(currentDate, 1); // Move to the next day
            }
        });
        console.debug('events after', newList)
        return newList;
    }

    // get data from umbraco
    async function getSeasons(year) {
        try {
            const res = await axiosUmbraco.post(`api/Show/FilterShows`, { year: year, take: 1000, langauge: currentLanguage });
            if (res.status == 200) {
                const formattedData = res?.data?.result?.map((item, index) => ({
                    // id: `${index}event`,
                    startDate: item?.startDate ?? '',
                    start: formatDate(item?.startDate, true) ?? '',
                    date: formatDate(item?.startDate, true) ?? '',
                    end: formatDate(item?.endDate, true),
                    endDate: item?.endDate,
                    title: item?.showName,
                    background: 'red',
                    display: 'background',
                    color: 'white',
                    // classNames: ['hidden'],
                    flagURl: item?.countryFlag ?? '',
                }))
                setRealEvents(formattedData);
                setCalenderEvents(duplicateEventsBasedOnDates(formattedData));
            }
        } catch (error) {
            console.error('error in getting seasons', error)
        }
    }

    useEffect(() => {
        getSeasons(currentYear);
    }, [currentYear])

    function style(imgURL) {
        return ({
            backgroundImage: `url('${imgURL}')`,
            backgroundSize: 'cover', // Adjust the image size
            backgroundPosition: 'center', // Position the image
            height: '100%', // Example height
            borderRadius: '50%',
            transform: 'scale(1.5)',
            width: '100%', // Example width
        })
    }

    function EventStyle(imgURL) {
        return ({
            backgroundImage: `url('${imgURL}')`,
            backgroundColor: "#000",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
        })
    }



    const handleBack = () => {
        setCurrentYear((prev) => prev - 1);
    };
    const handleNext = () => {
        setCurrentYear((prev) => prev + 1);
    };

    return (

        <>
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="calnderuttonsContainer">
                        <div className="prev viewAllButton roboto-regular" onClick={() => handleBack()}>
                            &lt; </div>
                        <div className="year roboto-regular">{currentYear}</div>
                        <div className="next viewAllButton roboto-regular" onClick={() => handleNext()}>
                            &gt;
                        </div>
                    </div>
                </div>
            </div>
            {calenderEvents.length > 0 &&
                <div className="row">
                    {renderCalender(calenderEvents, Today, style, realEvents, EventStyle, '01')}
                    {renderCalender(calenderEvents, Today, style, realEvents, EventStyle, '02')}
                    {renderCalender(calenderEvents, Today, style, realEvents, EventStyle, '03')}
                    {renderCalender(calenderEvents, Today, style, realEvents, EventStyle, '04')}
                    {renderCalender(calenderEvents, Today, style, realEvents, EventStyle, '05')}
                    {renderCalender(calenderEvents, Today, style, realEvents, EventStyle, '06')}
                    {renderCalender(calenderEvents, Today, style, realEvents, EventStyle, '07')}
                    {renderCalender(calenderEvents, Today, style, realEvents, EventStyle, '08')}
                    {renderCalender(calenderEvents, Today, style, realEvents, EventStyle, '09')}
                    {renderCalender(calenderEvents, Today, style, realEvents, EventStyle, '10')}
                    {renderCalender(calenderEvents, Today, style, realEvents, EventStyle, '11')}
                    {renderCalender(calenderEvents, Today, style, realEvents, EventStyle, '12')}
                </div>
            }
        </>


    )
}
function renderCalender(calenderEvents, Today, style, realEvents, EventStyle, month) {
    const currentLanguage = localStorage.getItem('ecupLang') ?? 'en';

    const eventArray = realEvents.filter((item) => (new Date(item.start).getMonth() == (parseInt(month) - 1) && Today.getFullYear() == new Date(item.start).getFullYear()));
    if (eventArray.length > 0) {
        return <div className="col-lg-4 col-md-4 col-sm-12 calenderContainer">
            <FullCalendar
                key={month + 'month' + Today.getFullYear()}
                plugins={[dayGridPlugin]}
                initialView="dayGridMonth"
                aspectRatio={1}
                locale={currentLanguage}
                headerToolbar={{ start: false, center: 'title', end: false }}
                themeSystem={'bootstrap5'}
                events={calenderEvents}
                eventOverlap={false}
                slotEventOverlap={false}
                initialDate={`${Today.getFullYear()}-${month}-01`}
                displayEventEnd={false}
                eventContent={(eventInfo) => {
                    return (
                        <div style={style(eventInfo.event._def.extendedProps.flagURl)} />
                    );
                }} />
            <div>
                {eventArray.map((item) => (
                    <div className="EventsRow">
                        <div className="eventFlag" style={EventStyle(item.flagURl)}></div>
                        <div className="eventDate">{new Date(item.start).getDate()} - {new Date(item.end).getDate()}</div>
                        <div className="eventName">{item.title}</div>
                    </div>

                ))}
            </div>
        </div>;
    }
}

