import React from 'react'
import ContactForm from '../components/ContactForm'

export default function ContactUs({ data , toast }) {
    return (
        <div className='row my-3'>
            <div className="col-lg-7 col-md-7 col-sm-12">
                <h3 className="NewsPostTitle bold" > We welcome your inquiries</h3>
                <p className="Contact-US-Desc">Feel free to contact us and we will get back to you as soon as possible! Fill this form or contact us directly.

                </p>
                <div className="contactUsLable">
                    <i className="fa fa-map-marker me-2"></i>
                    <span>Address</span>
                    <p>{data?.address}</p>
                    <div>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10880.55723120993!2d54.36395190190899!3d24.48718032014602!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5e6666e076cc95%3A0xc338a8b5c9f3925e!2sEmirates%20Arabian%20Horse%20Society!5e0!3m2!1sen!2sae!4v1714571792691!5m2!1sen!2sae" width="100%" height="150" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
                <a className="contactUsLable" href="mailto:eahgc@eahgc.com">
                    <i className="fa fa-envelope me-2"></i>
                    <span>Email</span>
                    <p>{data?.email}</p>
                </a>
                <a className="contactUsLable" href="tel:+971-26269222">
                    <i className="fa fa-phone me-2"></i>
                    <span className="roboto-regular">Phone</span>
                    <p>{data?.phone}</p>
                </a>
            </div>
            <div className='col-lg-5 col-md-5 col-sm-12'>
                <ContactForm key={'contactForm'} toast={toast} />
            </div>
        </div>

    )
}
