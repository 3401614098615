import React, { useEffect, useState } from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import axiosUmbraco from '../axios/axiosUmbraco';
import { formatDate } from '../helper/formatDate';
import { Link } from 'react-router-dom';
import { useTranslate } from './TranslationText';
const BaseURL = process.env.REACT_APP_BACKEND_API;
const pageId = process.env.REACT_APP_NEWS_ID;


export default function NewsSection() {

    const { translate } = useTranslate();
    const carouselOption = {
        loop: true,
        margin: 10,
        dotsEach: false,
        dots: false,
        nav: true,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 2
            },
            1000: {
                items: 2.5
            }
        }
    }

    const [newsItems, setNewsItems] = useState([]);

    // get data from umbraco
    async function getNews() {
        try {
            const res = await axiosUmbraco.get(`/umbraco/delivery/api/v2/content?fetch=children:${pageId}&skip=0&take=1000`);

            if (res.status == 200) {
                const formattedData = res?.data?.items?.map((item) => ({
                    date: item?.properties?.newsDate ?? '',
                    link: item?.properties?.externalLink ?? '',
                    name: item?.properties?.newsTitle ?? '',
                    description: item?.properties?.newsShortDescription ?? '',
                    image: item?.properties?.newsImage.length > 0 ? item?.properties?.newsImage[0].url : '',
                }))
                console.debug('news Are', res.data)
                setNewsItems(formattedData);
            }
        } catch (error) {
            console.error('error in getting seasons', error)
        }
    }

    useEffect(() => {
        getNews();
    }, [])


    return (
        newsItems.length > 0 &&
        <div className="secondSectionContainer mediaGalleryTitle">
            <div id="news" className="seasonSectionTitle mediaGalleryTitle">
                <span className="roboto-light">{translate('web.news', 'News')}</span>
            </div>
            <OwlCarousel className='owl-theme' {...carouselOption} dir='ltr'>
                {newsItems.map((item) => (
                    <Link to={item.link} target='_blank' className="item pointer" >
                        <div className="newsCard">
                            <div className="newsImageContainer">
                                <img src={BaseURL + item.image} alt="ChangeMe" />
                            </div>
                            <div className="newsPost">
                                <div className="newsPostTitle roboto-bold">
                                    {item.name}
                                </div>
                                <div className="newsPostDesc roboto-regular">
                                    {item.description}
                                </div>
                                <div className="newsPostDate roboto-bold">
                                    <i className="fa fa-calendar iconRed"></i> <span className="newsDate">
                                        {formatDate(item.date)}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </Link>
                ))}
            </OwlCarousel>
        </div>
    )
}
