import React from 'react'
import { formatDate } from '../helper/formatDate'

export default function CategoryCard({ translate, item }) {
    return (
        <div className='col-lg-4 col-md-6 col-sm-6 col-12 my-1'>
            <div className='horseCard bgGray text-start mx-3 pb-3'>
                <div className='w-100' >
                    <i className="fa fa-object-group" aria-hidden="true"></i> <span style={{ fontWeight: 'bold' }}>{translate('web.class', 'Class')}:</span> {item.className}
                </div>
                <div className='w-100' ><i className="fa fa-calendar me-2"></i><span style={{ fontWeight: 'bold' }}>{translate('web.birthdate', 'Birthdate')}:</span> <span style={{ fontWeight: 'bold' }}>{translate('web.from', 'From')}</span> {formatDate(item.birthDateFrom)} <span style={{ fontWeight: 'bold' }}>{translate('web.to', 'To')}</span> {formatDate(item.birthDateTo)}</div>
                {item.maxNumPerClass > 0 ?
                    <div className='w-100' >
                        <img src='./svgs/horse-head-solid.svg' style={{ height: 15 }} /> <span style={{ fontWeight: 'bold' }}>{translate('web.horses', 'Horses')}:</span> {item.completedHorses} <span style={{ fontWeight: 'bold' }}>{translate('web.of', 'of')}</span> {item.completedHorses + item.remainingHorses} <span className='text-danger' style={{ fontWeight: 'bold' }}>{translate('web.remaining', 'Remaining')} {item.remainingHorses}</span>
                    </div>
                    :
                    <div className='w-100' >
                        <img src='./svgs/horse-head-solid.svg' style={{ height: 15 }} /> <span style={{ fontWeight: 'bold' }}>{translate('web.horses', 'Horses')}:</span> {item.completedHorses} <span style={{ fontWeight: 'bold' }}>{translate('web.andNoMaxNumber', 'And No Max Number')}</span>
                    </div>
                }
            </div>
        </div>
    )
}
