import axios from "axios";

const baseURL = process.env.REACT_APP_BACKEND_API;

const axiosUmbraco = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json", 
    "cache-control": "no-cahce",
    "Referer": window.location.href,
    "Accept": 'application/json, text/plain, */*',
    "accept-language": getCurrentLanguageUmbraco(),
  },
});

axiosUmbraco.interceptors.request.use(
  (config) => {
    const language = getCurrentLanguage(); 
    if (language) {
      config.params = {
        ...config.params,
        language: language,
      };
    }
    return config;
  },
  (error) => Promise.reject(error)
);



function getCurrentLanguage() {
  const lang = localStorage.getItem("ecupLang") ?? "en";
  if (lang == "en") return 0;
  else return 1; // Defaulting to English for demonstration
}

function getCurrentLanguageUmbraco() {
  // Replace this with your logic to retrieve the current language
  // For example, you can retrieve it from localStorage or from a state variable
  const lang = localStorage.getItem("ecupLang") ?? "en";
  if (lang == "en") return "en-US";
  else return "ar-AE"; // Defaulting to English for demonstration
}
export default axiosUmbraco;
