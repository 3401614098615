const initialState = {
    translations: [],
    loading: false,
    error: null,
};

const translationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_TRANSLATIONS_REQUEST':
            return { ...state, loading: true, error: null };
        case 'FETCH_TRANSLATIONS_SUCCESS':
            return {
                ...state, loading: false, translations: action.payload.reduce(function (
                    previous,
                    current
                ) {
                    previous[current.key] = current.value;
                    return previous;
                },
                    {})
            };
        case 'FETCH_TRANSLATIONS_FAILURE':
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

export default translationsReducer;
