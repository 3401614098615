import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'
import { fetchTranslations } from '../Redux/actions/translationsActions';
import TranslationText from './TranslationText';
const baseURL = process.env.REACT_APP_BASE_URL;

export default function Header({ data }) {
    const [isloggedInUser, setIsloggedInUser] = useState(!!(localStorage.getItem('accessTokenforECUP') ?? '') ? true : false);
    const [language, setLanguage] = useState(localStorage.getItem('ecupLang') || 'en');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        const token = localStorage.getItem('accessTokenforECUP') ?? '';
        if (!!token) {
            setIsloggedInUser(true);
        } else {
            setIsloggedInUser(false);
        }
    }, [navigate]);

    const handleLogout = () => {
        localStorage.clear();
        window.location.reload()
    }
    // hangle language Change

    useEffect(() => {
        if (language == 'ar') {
            document.documentElement.dir = 'rtl'; // Set the "dir" attribute on <html>
            document.documentElement.lang = language;    // Set the "lang" attribute on <html>
        }
    }, [language]); // Re-run when direction or lang changes

    useEffect(() => {
        dispatch(fetchTranslations());
    }, [language]);

    function chengelanguage() {
        if (language == 'en') {
            localStorage.setItem('ecupLang', 'ar');
            setLanguage('ar');
            window.location.reload();
        } else {
            localStorage.setItem('ecupLang', 'en');
            setLanguage('en');
            window.location.reload();
        }
    }

    return (
        <>
            {/* <!-- Menu OverLay div --> */}
            {language == 'ar' &&
                <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.rtl.min.css" integrity="sha384-gXt9imSW0VcJVHezoNQsP+TNrjYXoGcrqBZJpry9zJt8PCQjobwmhMGaDHTASo9N" crossorigin="anonymous" />}
            {language == 'en' &&
                <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css" rel="stylesheet"
                    integrity="sha384-QWTKZyjpPEjISv5WaRU9OFeRpok6YctnYmDr5pNlyT2bRjXh0JMhjY6hW+ALEwIH" crossorigin="anonymous" />}

            <link rel="stylesheet" href={`${baseURL}/css/style.css`} />
            {language == 'ar' && <link rel="stylesheet" href={`${baseURL}/css/rtl.css`} />}
            <div className="menuOverLay"></div>
            {/* <!-- header --> */}
            <div className="headerContainer">
                <div className="container">
                    <div className="header">
                        <img className="headerLogo" src="./img/logo.png" alt="logo" />
                        <div className="searchBar">
                            <i className="fa fa-search" aria-hidden="true"></i>
                            <input type="text" placeholder="Search" name="search" id="" />
                        </div>
                        <div className="headerMenu">
                            <Link to="/" className="menuItem roboto-regular">
                                <TranslationText defaultText={'Home'} textkey={'Home'} key={'Home'} />
                            </Link>
                            <Link to="/rules" className="menuItem roboto-regular">
                                <TranslationText defaultText={'Rules'} textkey={'Rules'} key={'Rules'} />
                            </Link>
                            <Link to="/calender" className="menuItem roboto-regular">
                                <TranslationText defaultText={'Calendar'} textkey={'Calendar'} key={'Calendar'} />
                            </Link>
                            <Link to="/#news" className="menuItem roboto-regular">
                                <TranslationText defaultText={'News'} textkey={'News'} key={'News'} />
                            </Link>
                            <Link to="/#aboutSection" className="menuItem roboto-regular">
                                <TranslationText defaultText={'About Us'} textkey={'About'} key={'About Us'} />

                            </Link>
                            <Link to="/contactUs" className="menuItem roboto-regular">
                                <TranslationText defaultText={'Contact us'} textkey={'Contact'} key={'Contact us'} />
                            </Link>
                            {isloggedInUser ?
                                <div class="dropdown">
                                    {/* <Link to="/profile" className="menuItem roboto-regular btn btn-secondary dropdown-toggle" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Profile
                                    </Link> */}
                                    <div class="menuItem roboto-regular dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                        <TranslationText defaultText={'My Profile'} textkey={'web.MyProfile'} key={'My Profile'} />
                                    </div>
                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li><Link to="/profile" class="dropdown-item">
                                            <TranslationText defaultText={'Account Setting'} textkey={'web.accountSetting'} key={'Account Setting'} />
                                        </Link></li>
                                        <li><div class="dropdown-item" onClick={handleLogout}>
                                            <TranslationText defaultText={'LogOut'} textkey={'web.logout'} key={'Log out'} />
                                        </div></li>
                                    </ul>
                                </div>
                                :
                                <Link to="/login" className="menuItem roboto-regular">
                                    <TranslationText defaultText={'Login'} textkey={'web.login'} key={'Login'} />
                                </Link>
                            }
                            <Link onClick={() => { chengelanguage() }} className="menuItem roboto-regular">
                                {language == 'en' ?
                                    <>
                                        <TranslationText defaultText={'AR'} textkey={'web.ar'} key={'AR'} />
                                        <img src="./img/ar.png" className="langicon" alt="" />
                                    </>
                                    :
                                    <>
                                        <TranslationText defaultText={'انكليزي'} textkey={'web.en'} key={'EN'} />
                                        <img src="./img/en.png" className="langicon" alt="" />
                                    </>
                                }
                            </Link>
                        </div>
                        <a className="BurgerIcon">
                            <i className="fa fa-bars" aria-hidden="true"></i>
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}
