import React from 'react'
const BaseURL = process.env.REACT_APP_BACKEND_API;

export default function HomePageImageVideo({ slides }) {

    console.debug('slides are', slides);
    return (
        slides.length > 0 &&
        <div className="firstSliderContainer text-center">
            {slides[0].properties.sliderImage[0].mediaType == "umbracoMediaVideo" ?
                <video src={`${BaseURL}${slides[0].properties.sliderImage[0].url}`} style={{ width: '100%', margin: ' auto', marginTop: 20, marginBottom: 20 }} controls />
                :
                <img src={`${BaseURL}${slides[0].properties.sliderImage[0].url}`} alt="ChangeMe" style={{ width: '100%', margin: ' auto', marginTop: 20, marginBottom: 20 }} />
            }

        </div>
    )
}
