import React, { useEffect, useState } from 'react'
import axiosUmbraco from '../axios/axiosUmbraco';
import { formatDate } from '../helper/formatDate';
import { Link } from 'react-router-dom';
import { useTranslate } from './TranslationText';
const BaseURL = process.env.REACT_APP_BACKEND_API;
const pageId = process.env.REACT_APP_SEASONS_ID;

export default function SeasonSection() {

    const [seasonItems, setSeasonItems] = useState([]);
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const currentLanguage = localStorage.getItem('ecupLang') ?? 'en';
    const {translate} = useTranslate();


    // get data from umbraco
    async function getSeasons(year) {
        try {
            const res = await axiosUmbraco.post(`api/Show/FilterShows`, { year: year, take: 1000, langauge: currentLanguage });
            debugger;
            if (res.status == 200) {
                const formattedData = res?.data?.result?.map((item) => ({
                    name: item?.showName ?? '',
                    address: item?.countryName ?? '',
                    date: item?.startDate ?? '',
                    flag: item?.countryFlag ?? '',
                    showImage: item?.logo ?? '',
                    id: item?.id ?? '',
                    registrationOpen: item?.isShowRegisterStarted ?? false,
                }))
                setSeasonItems(formattedData);
            }
        } catch (error) {
            console.error('error in getting seasons', error)
        }
    }

    useEffect(() => {
        getSeasons(currentYear);
    }, [currentYear])

    function goPrev() {
        setCurrentYear((prev) => prev - 1);
    }
    function goNext() {
        setCurrentYear((prev) => prev + 1);
    }

    return (

        <div id="seasons" className="thirdSectionContainer">
            <div className="seasonSectionTitle">
                <span className="roboto-light">  {translate('web.season' , 'Season')} <span id="spn-season">{currentYear}</span> </span>
                <div className="seasonButtonsContainer">
                    <i className="fa fa-chevron-up colorbgwhite" onClick={() => goPrev()}></i>
                    <i className="fa fa-chevron-down" onClick={() => goNext()}></i>
                </div>
            </div>
            {seasonItems.length > 0 &&
                <div className="seasonCardsContainer">
                    {seasonItems.map((show) => (
                        <Link to={`/showDetails?id=${show?.id}`} className="seasonCard px-3">
                            <div className="seasoncardImageContainer">
                                <img src={show.showImage} alt="ChangeMe" />
                            </div>
                            <div className="seasonPostBody">
                                <div className="flagimgandcalandericon">
                                    <img src={show.flag} alt="ChangeMe" />

                                    <i className="fa fa-calendar iconRed"></i>
                                </div>
                                <div className="seasonspacer"></div>
                                <div className="seasonbody" style={{ maxWidth: 150 }}>
                                    <div className="seasonTitle roboto-bold">
                                        {show.name}
                                    </div>
                                    <div className="seasonAddress roboto-bold">
                                        {show.address}
                                    </div>
                                    <div className="seasonDate roboto-medium">
                                        {formatDate(show.date)}
                                    </div>
                                </div>
                                {show?.registrationOpen &&
                                    <div className='text-center btn btn-danger mx-2 isOpendNow' style={{ maxHeight: 62 }}>Patricipate now</div>
                                }
                            </div>
                        </Link>
                    ))}
                </div>
            }
            <div className="seasonrow">
            </div>
        </div>
    )
}
