import React, { useEffect, useState } from 'react'
import ImageSlider from '../components/ImageSlider'
import { formatDate } from '../helper/formatDate'
import OrganiserInfo from '../components/OrganiserInfo'
import JudgesList from '../components/JudgesList'
import CompetitionsSection from '../components/CompetitionsSection'
import ClassesSection from '../components/ClassesSection'
import CustomButton from '../components/CustomButton';
import { useLocation, useNavigate } from 'react-router'
import axiosBackend from '../axios/axios'
import CategoriesSection from '../components/CategoriesSection'
import PrizeMoney from '../components/PrizeMoney'
import { Link } from 'react-router-dom'
import { useTranslate } from '../components/TranslationText'
import HorizentalSpacer from '../components/HorizentalSpacer'
export default function ShowDetailsPage() {
    const { translate } = useTranslate();
    const navigate = useNavigate();
    const [showData, setShowData] = useState({});
    const [loaded, setLoaded] = useState(false);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const showID = searchParams.has("id")
        ? searchParams.get("id")
        : false;

    const accessToken = localStorage.getItem('accessTokenforECUP') ?? false;

    async function getShowDetails() {
        try {
            const res = await axiosBackend.get(`/api/Show/GetShowEntrybyId?id=${showID}`);
            if (res.status == 200) {
                if (res.data.responseCode == 200) {
                    setShowData(res.data.result);
                    setLoaded(true);
                }
            }

        } catch (error) {
            console.debug('error', error);
        }
    }

    useEffect(() => {
        getShowDetails();
    }, [])


    return (
        loaded && <div className='row pt-3'>
            {/* <h2 className='roboto-light contentGray' >{showData?.showName}</h2> */}
            <h2 className='roboto-custom-black col-sm-8 col-12 fw-bold' >{showData?.showName}</h2>
            <div className='roboto-custom-black col-sm-4 col-12' >
                {!!accessToken ?
                    <CustomButton type='navigate' htmlText={`${translate('web.participateNow', 'Participate Now')}`} className='btn-success' key={'participate'} onClick={() => navigate(`/showEntry?id=${showID}`)} />
                    :
                    <CustomButton type='navigate' htmlText={`${translate('web.participateNow', 'Participate Now')}`} className='btn-success' key={'participate'} onClick={() => navigate(`/login?redirectUrl=/showEntry?id=${showID}`)} />
                }
            </div>
            <div className='col-lg-8 col-md-6 col-sm-12'>
                <div className='col-12 mb-2 d-flex flex-wrap grayText' >
                    <span><i className="fa fa-calendar" aria-hidden="true"></i> {formatDate(showData?.startDate ?? new Date())}</span>
                    <span className='ms-3' ><i className="fa fa-calendar" aria-hidden="true"></i> {formatDate(showData?.endDate ?? new Date())}</span>
                    <span className='ms-3'><i className="fa fa-map-marker" aria-hidden="true"></i> {showData?.address}</span>
                    <span className='ms-3'><i className="fa fa-trophy" aria-hidden="true"></i> {showData?.showRank}</span>
                    <span className='ms-3'><i className="fa fa-hourglass-start" aria-hidden="true"></i> {formatDate(showData?.registerStartDate)}</span>
                    <span className='ms-3'><i className="fa fa-hourglass-end" aria-hidden="true"></i> {formatDate(showData?.registerEndDate)}</span>
                    <Link to={`/showParticpateConditions?showId=?${showID}`} className='ms-3 text-primary text-decoration-underline'><i className="fa fa-info-circle" aria-hidden="true"></i> {translate('web.particaptionTermsAndConditions', 'Particapte Conditions')}</Link>
                </div>
                <ImageSlider
                    key={'slider'}
                    items={[
                        { type: 'video', link: showData?.liveLink },
                        { type: 'video', link: showData?.promoVideoLink },
                        { type: 'img', link: showData?.coverImage },
                        { type: 'img', link: showData?.promoImage },
                        { type: 'img', link: showData?.countryFlag },
                        { type: 'img', link: showData?.logo },
                    ]} />
            </div>
            <div className='col-lg-4 col-md-6 col-sm-12'>
                <OrganiserInfo key={'orgnaiser Info'} data={showData} />
                <JudgesList key={'judges List'} />
            </div>
            <HorizentalSpacer key={'h4'} />
            <CompetitionsSection key={'compititions'} showID={showID} />
            <HorizentalSpacer key={'h1'} />
            <CategoriesSection key={'categories'} showId={showID} />
            <HorizentalSpacer key={'h2'} />
            <PrizeMoney showId={showID} key={'prizeMoney'} />
            <HorizentalSpacer key={'h3'} />
            <ClassesSection key='classes' showID={showID} />
        </div>
    )
}
