import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from './components/Layout';
import LoginPage from './pages/LoginPage';
import HomePage from './pages/HomePage';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RegisterPage from "./pages/RegisterPage";
import ForgetPassword from "./pages/ForgetPassword";
import ShowDetailsPage from "./pages/showDetailsPage";
import ShowEntry from "./pages/ShowEntry";
import AddNewHorsePage from "./pages/AddNewHorsePage";
import Profilepage from "./pages/Profilepage";
import { useEffect, useState } from "react";
import axiosUmbraco from "./axios/axiosUmbraco";
import RulesPage from "./pages/RulesPage";
import CalanderPage from "./pages/CalanderPage";
import ContactUs from "./pages/ContactUs";
import { Provider } from 'react-redux';
import store from './Redux/store';

const homePageID = process.env.REACT_APP_UMBRACOHOMEPAGE_ID;

function App() {
  const [homePageData, setHomePageData] = useState({});
  // get HomePage Data
  async function getHomePage() {
    try {
      const res = await axiosUmbraco.get(`/umbraco/delivery/api/v2/content/item/${homePageID}`);
      console.debug('homePage Data is', res);
      setHomePageData(res.data.properties);
    } catch (error) {
      console.error('error in Getting Home Page Data')
    }

  }
  useEffect(() => {
    getHomePage();
  }, [])

  const dismissAll = () => toast.dismiss();

  return (
    <Provider store={store}>
      <Router>
        <Layout data={homePageData}>
          <ToastContainer position="top-right" onClick={dismissAll} theme="colored" autoClose={10000} />
          <Routes>
            <Route path='/' element={<HomePage key={'homepage'} data={homePageData} />} />
            <Route path='/login' element={<LoginPage key={'LoginPage'} toast={toast} />} />
            <Route path='/register' element={<RegisterPage key={'RegisterPage'} toast={toast} />} />
            <Route path='/forgetPassword' element={<ForgetPassword key={'ForgetPassword'} toast={toast} />} />
            <Route path='/showDetails' element={<ShowDetailsPage key={'ShowDetails'} toast={toast} />} />
            <Route path='/showEntry' element={<ShowEntry key={'showEntry'} toast={toast} />} />
            <Route path='/addNewHorse' element={<AddNewHorsePage key={'addNewHorse'} toast={toast} />} />
            <Route path='/profile' element={<Profilepage key={'profile'} toast={toast} />} />
            <Route path='/rules' element={<RulesPage key={'rules'} toast={toast} />} />
            <Route path='/calender' element={<CalanderPage key={'calender'} toast={toast} />} />
            <Route path='/contactUs' element={<ContactUs key={'contactus'} toast={toast} data={homePageData} />} />
          </Routes>
        </Layout>
      </Router>
    </Provider>
  );
}

export default App;
