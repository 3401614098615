import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import { useTranslate } from '../components/TranslationText';
import ForgetPasswordForm from '../components/ForgetPasswordForm';
import OTPForm from '../components/OTPForm';
import axiosInstance from '../axios/axios';

export default function ForgetPassword({ toast }) {
    const navigate = useNavigate();
  const { translate } = useTranslate();


  const [isWideScreen, setIsWideScreen] = useState(true);
 

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth >= 600);
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  

 
    return (
        <>
            <div className="LoginPage">
                {isWideScreen && <div style={{ flex: 1 }}></div>}
                <div className="LoginFormContainer">
                    <ForgetPasswordForm toast={toast} />
                </div>
            </div>
        </>
    )
}
